// NotFound.js (add CSS styling)
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: 50px;
`;

const NotFound = () => {
  return (
    <Container>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </Container>
  );
};

export default NotFound;
