
import logo from "../../images/d-launchpad.png";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate,
} from "react-router-dom";
import { TbCircleArrowUpRightFilled } from 'react-icons/tb';
import{SiAdguard} from 'react-icons/si'
import { IoHome, IoBulbOutline } from 'react-icons/io5';
import { RiNewspaperLine, RiFilePaperLine } from 'react-icons/ri';
import { TbHexagons } from 'react-icons/tb';
import { CiMap } from 'react-icons/ci';
import { AiOutlineTeam } from 'react-icons/ai';
import { HashLink } from 'react-router-hash-link';
import WhitePaper from '../../White-Paper-final.pdf';
import lite from '../../lite paper.pdf';
import Web3 from 'web3';
import { CgArrowRightO } from 'react-icons/cg';
import { LiaSortAmountUpSolid } from 'react-icons/lia';
import { GoChecklist } from 'react-icons/go';
import DAOStarter from '../../images/Links/DAOStarter.png'
import KDG from '../../images/Links/KDG.svg'
import Gagarin from '../../images/Links/Gagarin.svg'
import DAOLaunch from '../../images/Links/DAOLaunch.png'

import Marquee from "../separate/Marquee";

function Home() {
    const navigate = useNavigate();

    const getacconut = async (e) => {
        var web3js = new Web3(window.ethereum);
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        navigate('/idolaunch')
    }

    return (
        <div className="App">
            <Marquee />
            <nav class="navbar navbar-expand-lg bg-tranaprent navbar-fixeds">
                <div class="container-fluid col-lg-10 navbar-background-color">
                    <a class="navbar-brand" href="/"><img src={logo} alt="logo" /></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            {/* <li class="nav-item">
                                <HashLink class="nav-link" to="/idolaunch"><SiAdguard className="navbar-navlink-icon" />Your Projects</HashLink>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href={WhitePaper} target="_blank"><RiNewspaperLine className="navbar-navlink-icon" />White Paper </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href={lite} target="_blank"><RiFilePaperLine className="navbar-navlink-icon" />Lite Paper </a>
                            </li>
                            <li class="nav-item">
                                <HashLink class="nav-link" to="/#roadmap"><CiMap className="navbar-navlink-icon" />RoadMap</HashLink>
                            </li>
                            <li class="nav-item">
                                <HashLink class="nav-link" to="/#coinomics"><TbHexagons className="navbar-navlink-icon" />Coinomics</HashLink>
                            </li>
                            <li class="nav-item">
                                <HashLink class="nav-link" to="/#vesting"><TbHexagons className="navbar-navlink-icon" />vesting</HashLink>
                            </li> */}

                            {/* <li class="nav-item">
                                <HashLink class="nav-link" to="/#team"><AiOutlineTeam className="navbar-navlink-icon" />Team</HashLink>
                            </li> */}
                            {/* <li class="nav-item">
                                <HashLink class="nav-link border-0" to="/leaderboard"><AiOutlineTeam className="navbar-navlink-icon" />Leaderboard</HashLink>
                            </li> */}
                            <li class="nav-item">
                                <HashLink class="nav-link" to="/idolaunch"><GoChecklist className="navbar-navlink-icon" />I want to Invest </HashLink>
                            </li>
                            <li class="nav-item">
                                <HashLink class="nav-link border-0" to="/idoform"><LiaSortAmountUpSolid className="navbar-navlink-icon" />I want to Raise Funds</HashLink>
                            </li>
                            {/* <li class="nav-item">
                                <div class="dropdown">
                                    <button class="btn our-product-dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Our Products 
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">D-WALLET</a></li>
                                        <li><a class="dropdown-item" href="#">D-SWAP</a></li>
                                        <li><a class="dropdown-item" href="#">D-NFT</a></li>
                                    </ul>
                                </div>
                            </li> */}
                            {/* <li class="nav-item"> */}
                                {/* <button class="nav-lin border-0 navbar-connect-wallet" onClick={getacconut} to="/">Buy D-Coin</button> */}
                                {/* <button class="btn nav-link border-0 navbar-connect-wallet" data-bs-toggle="modal" data-bs-target="#buyCoinModal">Buy D-Coin</button> */}
                                {/* <a class="btn nav-link border-0 navbar-connect-wallet" href="https://sales.d-ecosystem.io/" target="_blank">Buy D-Coin</a> */}
                            {/* </li> */}

                        </ul>
                    </div>
                </div>
            </nav>


            <div class="modal fade manualconnect-modal-section" id="buyCoinModal" tabindex="-1" aria-labelledby="buyCoinModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header mb-0 pb-0 border-0">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='d-flex text-center justify-content-center align-items-center mb-3'>
                                <h3 className=''>Select your desired platform <br /> To get DCX coin </h3>
                            </div>
                            <div className='container pb-3'>
                    <div className='row row-cols-lg-1 g-4'>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://www.daostarter.pro/#/DAOStarter/index'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOStarter} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>DAOstarter - July 3rd - July 7th</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://app.gagarin.world/ido/649c236693ecbba890e6e8d1'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={Gagarin} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Gagarin - July 10th - July 13th</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://kingdomstarter.io/launchpad/upcoming/d-ecosystem'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={KDG} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Kingdom Starter - July 14th - July 16th</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://daolaunch.notion.site/b0ab81647df84690980d93deaad13bfe?v=95a0950059d54f348455afc241baa712'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOLaunch} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Dao Launch - July 17rd - July 20th</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      {/* <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://gagarin.world/'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={Gagarin} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>Gagarin - TBD</p>

                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div>
                      <div className='col made-by-decosystem-banner-section '>
                        <a target='_blank' href='https://daolaunch.net/'>
                          <div className='card d-flex flex-row justify-content-between align-items-center'>
                            <div >
                              <img className='buy-coin-links-logo-size' src={DAOLaunch} />
                            </div>
                            <div className=''>
                              <p className='mb-0'>DAO Launch - TBD</p>
                            </div>
                            <TbCircleArrowUpRightFilled className='buy-coin-links-icons-css' />
                          </div>
                        </a>
                      </div> */}
                    </div>
                  </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
