import * as React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import logo from "../images/d-ido.png";
import logo2 from "../images/d-ido-logo.png";
import { IoHome, IoBulbOutline } from 'react-icons/io5';
import { BsLightningChargeFill } from 'react-icons/bs';
import { CiMap } from 'react-icons/ci';
import { FaDiscord, FaTelegramPlane } from 'react-icons/fa';
import { AiOutlineTeam, AiOutlineInstagram, AiOutlineTwitter, AiFillFacebook, AiOutlineSearch } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import decosystem from "../images/finished-ido/d-ecosystem.png"
import { TfiWorld } from 'react-icons/tfi';
import Ethereum from "../images/ethereum.png"
import Matic from "../images/matic.png"
import BSC from "../images/coin/bnb.png"
import profile from "../images/profile.jpg"
import headingleft from "../images/inner/headingleft.png"
import headingright from "../images/inner/headingright.png"
import { CgLoadbarDoc } from 'react-icons/cg';
import facebook from "../images/social/facebook.png"
import github from "../images/social/github.png"
import linkedin from "../images/social/linkedin.png"
import twitter from "../images/social/twitter.png"
import { HashLink } from 'react-router-hash-link';
import Navbar from "../Pages/separate/Navbar";
import Footer from "../Pages/separate/Footer";
import Axios from 'axios';
import { useReactCountdown } from 'use-react-countdown';
import { API_BACKEND_URL } from '../config/config';
import { color } from '@amcharts/amcharts4/core';
import tokenlogo from '../images/toptoken_logo.png';

function Timerswiper2() {
    // Date in format (Month DD, YYYY hh:mm:ss)
    const { days, hours, minutes, seconds } = useReactCountdown(
        'March 29, 2023 17:30:00'
    );

    return (
        <div className="d-flex flex-row gap-2 justify-content-evenly">
            <div className="">
                <span className="">{days}</span>
                <span>D</span>
            </div>
            <div className="">
                <span className="">{hours}</span>
                <span>H</span>
            </div>
            <div className="">
                <span className="">{minutes}</span>
                <span>M</span>
            </div>
            <div className="">
                <span className="">{seconds}</span>
                <span>S</span>
            </div>
        </div>
    );
}


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            volumepath: [],
            activepath: [],
            completepath: [],
            upcommingProjectsState: [],
            activeProjectsState: [],
            CompletedProjectsState: []
        }
    }

    componentDidMount() {
        this.getido();
    }



    formatDateTime = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }


    getido = async () => {
        const getdetails = await Axios.get(`${API_BACKEND_URL}getIdoData`);
        const launchPad = getdetails.data.idoapp;
        // console.log('launchPad----', launchPad);
        const todayDate = new Date();

        const activeProjects = [];
        const upcomingProjects = [];
        const completedProjects = [];

        for (var i = 0; i < launchPad.length; i++) {
            const endDate = new Date(launchPad[i].enddate);
            const startDate = new Date(launchPad[i].startdate);
            const formattedStartDateTime = this.formatDateTime(startDate);
            const formattedEndDateTime = this.formatDateTime(endDate);
            const todayDateAndTime = this.formatDateTime(todayDate);
            // console.log('launchPad----', launchPad);
            if (todayDate >= startDate && todayDate <= endDate) {
                // Active project
                const project = {
                    _id: launchPad[i]._id,
                    name: launchPad[i].name,
                    description: launchPad[i].description,
                    tokensupply: launchPad[i].tokensupply,
                    initialsupply: launchPad[i].initialsupply,
                    tokenprice: launchPad[i].tokenprice,
                    hardcapvalue: launchPad[i].hardcapvalue,
                    projectname: launchPad[i].projectname,
                    blockchainvalues: launchPad[i].blockchainvalues,
                    startdate: formattedStartDateTime,
                    enddate: formattedEndDateTime,
                    image: BSC,
                    tokenname: launchPad[i].tokenname,
                    tokensymbol: launchPad[i].tokensymbol,
                    tokendecimal: launchPad[i].tokendecimal,
                };
                activeProjects.push(project);
            } else if (todayDate < startDate) {
                // Upcoming project
                const project = {
                    _id: launchPad[i]._id,
                    name: launchPad[i].name,
                    description: launchPad[i].description,
                    tokensupply: launchPad[i].tokensupply,
                    initialsupply: launchPad[i].initialsupply,
                    tokenprice: launchPad[i].tokenprice,
                    hardcapvalue: launchPad[i].hardcapvalue,
                    projectname: launchPad[i].projectname,
                    blockchainvalues: launchPad[i].blockchainvalues,
                    startdate: formattedStartDateTime,
                    enddate: formattedEndDateTime,
                    image: BSC,
                    tokenname: launchPad[i].tokenname,
                    tokensymbol: launchPad[i].tokensymbol,
                    tokendecimal: launchPad[i].tokendecimal,
                };
                upcomingProjects.push(project);
            } else {
                // Completed project
                const project = {
                    _id: launchPad[i]._id,
                    name: launchPad[i].name,
                    description: launchPad[i].description,
                    tokensupply: launchPad[i].tokensupply,
                    initialsupply: launchPad[i].initialsupply,
                    tokenprice: launchPad[i].tokenprice,
                    hardcapvalue: launchPad[i].hardcapvalue,
                    projectname: launchPad[i].projectname,
                    blockchainvalues: launchPad[i].blockchainvalues,
                    startdate: formattedStartDateTime,
                    enddate: formattedEndDateTime,
                    image: BSC,
                    tokenname: launchPad[i].tokenname,
                    tokensymbol: launchPad[i].tokensymbol,
                    tokendecimal: launchPad[i].tokendecimal,
                };
                completedProjects.push(project);
            }
        }

        // Set the state for active, upcoming, and completed projects
        this.setState({
            activeProjectsState: activeProjects,
            upcommingProjectsState: upcomingProjects,
            CompletedProjectsState: completedProjects,
        });


    }
    render() {

        return (
            <div className="App">
                <Navbar />
                <div class="hero-section-ido-launchpad-tabs-banner py-5">
                    <div class="container">
                        <div class="row g-4 justify-content-around">
                            <div class="col-lg-12">
                                <div className="d-flex flex-lg-row flex-column gap-3">
                                    <div className="">
                                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="pills-finished-tab" data-bs-toggle="pill" data-bs-target="#pills-finished" type="button" role="tab" aria-controls="pills-finished" aria-selected="true">Finished</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="false">Active</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active" id="pills-upcoming-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="pills-upcoming" aria-selected="false">Upcoming</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ms-lg-auto">
                                        {/* <div class="input-group">
                                        <span class="input-group-text" id="basic-addon1"><AiOutlineSearch /></span>
                                        <input type="text" class="form-control ps-0" placeholder="Search..." />
                                    </div> */}
                                    </div>
                                </div>
                                <div class="tab-content mt-5" id="pills-tabContent">
                                    <div class="tab-pane fade" id="pills-finished" role="tabpanel" aria-labelledby="pills-finished-tab" tabindex="0">
                                        <div class="row row-cols-1 row-cols-md-3 g-4">
                                            {this.state.CompletedProjectsState.map((complete) =>
                                                <div class="col">
                                                    
                                                    <div class="card h-100">
                                                        <div class="card-body px-0">
                                                            <div className="d-flex flex-row gap-3 px-3 mb-3">
                                                                <div className="steps-section-image-launch"><img src={profile} className="" alt="logo" /></div>
                                                                <div className="">
                                                                    <p className="ido-text-9 mb-0">{complete.tokenname}</p>
                                                                    <p className="ido-text-10 mb-1">Price USD : {complete.tokenprice} usd</p> </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 mt-2 align-items-center">
                                                                <div className="left-days-card-section">05 DAYS LEFT <br/><span className="ido-text-10">{complete.startdate}</span></div>
                                                                <div className="ms-auto me-3">
                                                                    <img style={{ width: "35px" }} src={complete.image} alt="logo" />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2 mt-4">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">TOKEN SUPPLY</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">{complete.tokensupply}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">INITIAL SUPPLY</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">{complete.initialsupply}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">TOKEN PRICE</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">{complete.tokenprice}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">HARDCAP</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">{complete.hardcapvalue}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2 mt-2 mb-3">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">SOCIAL</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1 d-flex">
                                                                        <AiOutlineTwitter className="ido-text-13" />
                                                                        <FaDiscord className="ido-text-13" />
                                                                        <AiOutlineInstagram className="ido-text-13" />
                                                                        <AiFillFacebook className="ido-text-13" />
                                                                        <FaTelegramPlane className="ido-text-13" />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab" tabindex="0">
                                        <div class="row row-cols-1 row-cols-md-3 g-4">
                                            {this.state.activeProjectsState.map((active) =>
                                                <div class="col">
                                                    <Link to={`/activeido/${active._id}`} state={{ from: this.state.activeProjectsState }}>
                                                    {/* <Link to={`https://buyidolauchpad.d-ecosystem.io/`} state={{ from: this.state.activeProjectsState }}> */}
                                                        <div class="card h-100">
                                                            <div class="card-body px-0">
                                                                <div className="d-flex flex-row gap-3 px-3 mb-3">
                                                                    <div className="steps-section-image-launch"><img src={tokenlogo} className="" alt="logo" /></div>
                                                                    <div className="">
                                                                        <p className="ido-text-9 mb-0">{active.projectname}</p>
                                                                        <p className="ido-text-10 mb-1">Price USD : {active.tokenprice} usd</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row gap-3 mt-2 align-items-center">
                                                                    <div className="left-days-card-section">LAUNCHED ON <br/><span className="ido-text-10">{active.startdate}</span></div>
                                                                    {/* <div className="ms-auto me-3">
                                                                        <img style={{ width: "35px" }} src={active.image} alt="logo" />
                                                                    </div> */}
                                                                </div>
                                                                <div className="d-flex flex-row gap-3 px-2 mt-4">
                                                                    <div className="steps-section-image">
                                                                        <p className="ido-text-11 mb-1">TOKEN Name</p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <p className="ido-text-12 mb-1">{active.tokenname}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row gap-3 px-2 mt-4">
                                                                    <div className="steps-section-image">
                                                                        <p className="ido-text-11 mb-1">TOKEN SUPPLY</p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <p className="ido-text-12 mb-1">{active.tokensupply}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                                    <div className="steps-section-image">
                                                                        <p className="ido-text-11 mb-1">INITIAL SUPPLY</p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <p className="ido-text-12 mb-1">{active.initialsupply}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                                    <div className="steps-section-image">
                                                                        <p className="ido-text-11 mb-1">TOKEN PRICE</p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <p className="ido-text-12 mb-1">{active.tokenprice}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                                    <div className="steps-section-image">
                                                                        <p className="ido-text-11 mb-1">HARDCAP</p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <p className="ido-text-12 mb-1">{active.hardcapvalue}</p>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="d-flex flex-row gap-3 px-2 mt-2 mb-3">
                                                                    <div className="steps-section-image">
                                                                        <p className="ido-text-11 mb-1">SOCIAL</p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <p className="ido-text-12 mb-1 d-flex">
                                                                            <AiOutlineTwitter className="ido-text-13" />
                                                                            <TfiWorld className="ido-text-13" />
                                                                            <CgLoadbarDoc className="ido-text-13" />
                                                                            <FaDiscord className="ido-text-13" />
                                                                            <AiOutlineInstagram className="ido-text-13" />
                                                                            <AiFillFacebook className="ido-text-13" />
                                                                            <FaTelegramPlane className="ido-text-13" />
                                                                        </p>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active" id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab" tabindex="0">

                                        <div class="row row-cols-1 row-cols-md-3 g-4">
                                            <div class="col">
                                                {this.state.upcommingProjectsState.map((upcomming) =>
                                                <Link to={`/upcommingidodetails/${upcomming._id}`}  state={{ from: this.state.upcommingProjectsState }} >
                                                    <div class="card h-100">
                                                        
                                                        <div class="card-body px-0">
                                                            <div className="d-flex flex-row align-items-center gap-3 px-3 mb-3">
                                                                <div className="steps-section-image-2"><img src={tokenlogo} className="" alt="logo" /></div>
                                                                <div className="ms-auto text-end">
                                                                    <p className="ido-text-9 mb-2">{upcomming.projectname}</p>
                                                                    <Link to={`/upcommingidodetails/${upcomming._id}`} state={{ from: this.state.upcommingProjectsState }} className='linkcolor'>Know More <BsArrowRight /></Link>
                                                                    {/* <a className='active-ido-href' href='https://www.d-ecosystem.io' target={"_blank"}>Know More <BsArrowRight /></a> */}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 mt-2 align-items-center">
                                                                <div className="left-days-card-section"><span className="ido-text-10">Coming Soon</span></div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2 mt-4">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">Coin Name</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">{upcomming.tokenname}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">Token Price</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">{upcomming.tokenprice}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">Total Token</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">{upcomming.tokensupply}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">Launching on</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">{upcomming.startdate}</p>
                                                                </div>
                                                            </div>
                                                            {/* <div className="d-flex flex-row gap-3 px-2">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">Min Purchase</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">100 DCX</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row gap-3 px-2">
                                                                <div className="steps-section-image">
                                                                    <p className="ido-text-11 mb-1">Max Purchase</p>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <p className="ido-text-12 mb-1">2,89,96,022 DCX</p>
                                                                </div>
                                                            </div> */}

                                                        </div>
                                                    </div>
                                                </Link>
                                                )}
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <Footer />
            </div>
        );
    }
}
export default Home;
