import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    useNavigate
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import logo from "../images/d-ido.png";
import { IoHome, IoBulbOutline } from 'react-icons/io5';
import { BsLightningChargeFill } from 'react-icons/bs';
import { CiMap } from 'react-icons/ci';
import { AiOutlineTeam, AiOutlineInstagram, AiOutlineTwitter, AiFillFacebook } from 'react-icons/ai';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Star2 from "../images/inner/star.png"
import headingleft from "../images/inner/headingleft.png"
import headingright from "../images/inner/headingright.png"

import { HashLink } from 'react-router-hash-link';
import Axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Navbar from "../Pages/separate/Navbar";
import Footer from "../Pages/separate/Footer";
import { API_BACKEND_URL } from '../config/config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MySwal = withReactContent(Swal)
function Home() {
    const [tokenValues, setTokenValues] = useState({
        tokensupply: "",
        initialsupply: "",
        tokenprice: "",
        hardcapvalue: "",
        softcapvalue: "",
        contractaddress: "",
        tokenname: "",
        tokensymbol: "",
        tokendecimal: "",
    });

    const [blockchainValues, setBlockchainValues] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const navigate = useNavigate()
    const {state}=useLocation();
    const {values,anon } = state;
    console.log(values)

    const [validation, setValidation] = useState({
        tokensupply: "",
        initialsupply: "",
        tokenprice: "",
        hardcapvalue: "",
        softcapvalue: "",
        contractaddress: "",
        tokenname: "",
        tokensymbol: "",
        tokendecimal: "",
    });

    useEffect(() => {
        checkValidation();
    }, [tokenValues]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTokenValues({ ...tokenValues, [name]: value });
    };

    const blockchainselect = (event) => {
        setBlockchainValues(event);
    };

    const startdates = (date) => {
        if (new Date() <= date || new Date() === date) {
            setStartDate(date);
        }
    };

    const enddates = (date) => {
        if (startDate <= date) {
            setEndDate(date);
        }
    };

    const checkValidation = () => {
        const errors = { ...validation };

        if (!tokenValues.tokenname.trim()) {
            errors.tokenname = "Token name is required";
        } else {
            errors.tokenname = "";
        }

        if (!tokenValues.tokendecimal.trim()) {
            errors.tokendecimal = "Token decimal is required";
        } else {
            errors.tokendecimal = "";
        }

        if (!tokenValues.tokensupply.trim()) {
            errors.tokensupply = "Token supply is required";
        } else {
            errors.tokensupply = "";
        }

        if (!tokenValues.initialsupply.trim()) {
            errors.initialsupply = "Initial supply is required";
        } else {
            errors.initialsupply = "";
        }

        if (!tokenValues.tokenprice.trim()) {
            errors.tokenprice = "Token price is required";
        } else {
            errors.tokenprice = "";
        }

        if (!tokenValues.hardcapvalue.trim()) {
            errors.hardcapvalue = "Hard cap value is required";
        } else {
            errors.hardcapvalue = "";
        }

        if (!tokenValues.softcapvalue.trim()) {
            errors.softcapvalue = "Soft cap value is required";
        } else {
            errors.softcapvalue = "";
        }

        if (!tokenValues.contractaddress.trim()) {
            errors.contractaddress = "Contract address is required";
        } else {
            errors.contractaddress = "";
        }

        setValidation(errors);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        var name = values.name;
        var email = values.email;
        var projectname = values.projectname;
        var description = values.description;
        var teams = anon;
        var investors = values.investors;
        var smartaudit = values.smartaudit;
        var whitepaperlink = values.whitepaperlink;
        var websitelink = values.websitelink;
        var githublink = values.githublink;
        var telegramgroup = values.telegramgroup;
        var telegramlink = values.telegramlink;
        var tokensupply = tokenValues.tokensupply;
        var initialsupply = tokenValues.initialsupply;
        var tokenprice = tokenValues.tokenprice;
        var hardcapvalue = tokenValues.hardcapvalue;
        var softcapvalue = tokenValues.softcapvalue;
        var blockchainvalues = blockchainValues;
        var contractaddress = tokenValues.contractaddress;
        var startdates = startDate;
        var enddates = endDate;
        var tokenname = tokenValues.tokenname;
        var tokensymbol = tokenValues.tokensymbol;
        var tokendecimal = tokenValues.tokendecimal;
        var userObject = {
            name,
            email,
            projectname,
            description,
            teams,
            investors,
            smartaudit,
            whitepaperlink,
            websitelink,
            githublink,
            telegramgroup,
            telegramlink,
            tokensupply,
            initialsupply,
            tokenprice,
            hardcapvalue,
            softcapvalue,
            blockchainvalues,
            contractaddress,
            startdates,
            enddates,
            tokenname,
            tokensymbol,
            tokendecimal
        };
        
        if (Object.values(validation).every((error) => !error)) {
             Axios.post(`${API_BACKEND_URL}idoapplication`, userObject, {
                "headers": {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                Swal.fire('Please wait for admin approval');
                navigate('/');
            }).catch((error) => {
                console.log(error)

            });
                console.log("Form submitted successfully!");

        } else {
            toast.error("please fill all the fields")
            console.log("Form has validation errors. Please fix them.");
        }
        // console.log('userObject---', userObject);
        // if (validation.allerror == 0) {
        //     console.log(validation,tokenValues)
        //     console.log(userObject)
        //     alert('yesss')
           
        // }else{
        //     alert('nooo')
        // }
    }
    return (
        <div className="App">
            <Navbar />
            <ToastContainer/>
            <div class="hero-section-ido-launchpad-banner py-5">
                <div class="container">
                    <div class="row g-4 justify-content-around">
                        <div class="col-lg-8 application-form-ido-section">
                            <p className="ido-text-6 mb-5">D-IDO Application Form<img className="star-second-image" src={Star2} alt="star" /></p>
                            <form className="" onSubmit={handleSubmit}>
                                {/* <div class="mb-4">
                                    <label class="form-label">Token Name</label>
                                    <input type="text" class="form-control" id="emailId" name='emailId' onChange={(e) => handleChange(e)} value={tokenValues.emailId} />
                                    {validation.emailId && <p className="errors" style={{ color: "red" }}>{validation.emailId}</p>}
                                </div> */}

                                <div class="mb-4">
                                    <label class="form-label">Token Name</label>
                                    <input type="text" class="form-control" id="tokenname" name='tokenname' onChange={(e) => handleChange(e) }value={tokenValues.tokenname} />
                                    {validation.tokenname && <p className="errors" style={{ color: "red" }}>{validation.tokenname}</p>}
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Token Symbol</label>
                                    <input type="text" class="form-control" id="tokensymbol" name='tokensymbol' onChange={(e) => handleChange(e)} value={tokenValues.tokensymbol} />
                                    {validation.tokensymbol && <p className="errors" style={{ color: "red" }}>{validation.tokensymbol}</p>}
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Total Token Supply</label>
                                    <input type="number" pattern="[-+]?[0-9]" class="form-control" id="tokensupply" name='tokensupply' onChange={(e) => handleChange(e)} value={tokenValues.tokensupply} />
                                    {validation.tokensupply && <p className="errors" style={{ color: "red" }}>{validation.tokensupply}</p>}

                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Initial Supply</label>
                                    <input type="number" pattern="[-+]?[0-9]" class="form-control" id="tokensupply" name='initialsupply' onChange={(e) => handleChange(e)} value={tokenValues.initialsupply} />
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Token Price</label>
                                    <input type="number" pattern="[-+]?[0-9]" class="form-control" id="tokenprice" name='tokenprice' onChange={(e) => handleChange(e)} value={tokenValues.tokenprice} />
                                    <div class="form-text">1 TKN = {tokenValues.tokenprice} USD</div>
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Start Date</label>
                                    <DatePicker selected={startDate} className="react-datepicker-wrapper22 form-control" onChange={(e) => startdates(e)} />
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">End Date</label>
                                    <DatePicker selected={endDate} className="react-datepicker-wrapper22 form-control" onChange={(e) => enddates(e)} />
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Hard Cap Value</label>
                                    <input type="number" pattern="[-+]?[0-9]" class="form-control" id="hardcapvalue" name='hardcapvalue' onChange={(e) => handleChange(e)} value={tokenValues.hardcapvalue} />
                                    {validation.hardcapvalue && <p className="errors" style={{ color: "red" }}>{validation.hardcapvalue}</p>}
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Soft Cap Value</label>
                                    <input type="number" pattern="[-+]?[0-9]" class="form-control" id="softcapvalue" name='softcapvalue' onChange={(e) => handleChange(e)} value={tokenValues.softcapvalue} />
                                    {validation.softcapvalue && <p className="errors" style={{ color: "red" }}>{validation.softcapvalue}</p>}
                                </div>
                                <div class="mb-4">
                                    <label for="exampleFormControlTextarea1" class="form-label">Network</label>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="d-chain" name="binance" onClick={(e) => blockchainselect("D-CHAIN")} />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            D-Chain
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="binance" name="binance" onClick={(e) => blockchainselect("BSC")} />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Binance Smart Chain
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="matic" name="binance" onClick={(e) => blockchainselect("MATIC")} />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Polygon (Matic)
                                        </label>
                                    </div>

                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Contract Address</label>
                                    <input type="text" class="form-control" id="contractaddress" name='contractaddress' onChange={(e) => handleChange(e)} value={tokenValues.contractaddress} />
                                    {validation.contractaddress && <p className="errors" style={{ color: "red" }}>{validation.contractaddress}</p>}
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Token Contract Decimal</label>
                                    <input type="text" class="form-control" id="tokendecimal" name='tokendecimal' onChange={(e) => handleChange(e)} value={tokenValues.tokendecimal} />
                                    {validation.tokendecimal && <p className="errors" style={{ color: "red" }}>{validation.tokendecimal}</p>}
                                </div>
                                <div className="text-center">
                                    <button type="submit" class="ido-launchpad-button">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    );
}

export default Home;
