import React, { useState, useEffect } from "react";

import { idoAbi } from './ido'
import Web3 from 'web3';
import $ from "jquery";
import Navbar from "../Pages/separate/Navbar";
import Footer from "../Pages/separate/Footer";

import { API_BACKEND_URL } from '../config/config';
import tokenContractAbi from '../ABI/tokenContractAbi.json';
import { ADMIN_WALLET_ADDRESS } from '../config/config';
import { BSC_NETWORK_NAME } from '../config/config';
import { MATIC_NETWORK_NAME } from '../config/config';
import { DCHAIN_NETWORK_NAME } from '../config/config';
import { BSC_CHAIN_ID } from '../config/config';
import { MATIC_CHAIN_ID } from '../config/config';
import { DCHAIN_CHAIN_ID } from '../config/config';
import Axios from 'axios';
import Star2 from "../images/inner/star.png"
import { MATIC_HEX_CHAIN_ID } from '../config/config';
import Swal from 'sweetalert2';


function ActiveYourPlan() {
    const [image, setimage] = useState("")
    const [fromvalue, setfromvalue] = useState("0")


    const [ProjectsDetails, setProjectsDetails] = useState([]);
    const [TokenContractAddress, setTokenContractAddress] = useState();
    const [Tokensymbol, setTokensymbol] = useState();
    const [SelectedTokenNetwork, setSelectedTokenNetwork] = useState();
    const [TokenPrice, setTokenPrice] = useState();
    const [TokenName, setTokenName] = useState();
    const [TokenDescription, setTokenDescription] = useState();
    const [TokenBalance, setTokenBalance] = useState();
    const [tokenAmount, setTokenAmount] = useState();
    const [TokenTotalSupply, setTokenTotalSupply] = useState();
    const [TokenInitialSupply, setTokenInitialSupply] = useState();
    const formatDateTime = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const getProjectsDetailsFromDB = async () => {
        const location = window.location.pathname.split('/')[2]
        // console.log('location---', location);

        const activeProjects = [];

        const getdetails = await Axios.post(`${API_BACKEND_URL}projectId`, { DBID: location });
        const launchPad = getdetails.data.idoapp;
        // console.log('launchPad---', launchPad);
        const endDate = new Date(launchPad.enddate);
        const startDate = new Date(launchPad.startdate);
        const formattedStartDateTime = formatDateTime(startDate);
        const formattedEndDateTime = formatDateTime(endDate);

        const project = {
            _id: launchPad._id,
            name: launchPad.name,
            description: launchPad.description,
            tokensupply: launchPad.tokensupply,
            initialsupply: launchPad.initialsupply,
            tokenprice: launchPad.tokenprice,
            hardcapvalue: launchPad.hardcapvalue,
            projectname: launchPad.projectname,
            blockchainvalues: launchPad.blockchainvalues,
            startdate: formattedStartDateTime,
            enddate: formattedEndDateTime,
            // image: BSC,
            tokenname: launchPad.tokenname,
            tokensymbol: launchPad.tokensymbol,
            tokendecimal: launchPad.tokendecimal,
            contractaddress: launchPad.contractaddress,
            image: launchPad.image
        };
        activeProjects.push(project);

        setProjectsDetails(activeProjects);

        setTokenContractAddress(launchPad.contractaddress);
        setTokensymbol(launchPad.tokensymbol);
        setSelectedTokenNetwork(launchPad.blockchainvalues);
        setTokenPrice(launchPad.tokenprice);
        setTokenName(launchPad.tokenname);
        setTokenDescription(launchPad.description)
        setimage(launchPad.image);
        setTokenTotalSupply(launchPad.tokensupply);
        setTokenInitialSupply(launchPad.initialsupply)
    };


    async function handleChange() {
        try {
            if (SelectedTokenNetwork == BSC_NETWORK_NAME) {
                const chainId = 0x61;
                var web3js = new Web3(window.ethereum);
                const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
                if (currentChainId !== chainId) {
                    try {
                        const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: web3js.utils.toHex(chainId) }] });
                        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
                        const tokenContract = new web3js.eth.Contract(tokenContractAbi, TokenContractAddress);
                        const tokenbal = await tokenContract.methods.balanceOf(account[0]).call();
                        const ercbal = await web3js.utils.fromWei(tokenbal, 'ether');
                        setTokenBalance(ercbal)
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                        }
                    }
                }
            } else if (SelectedTokenNetwork == MATIC_NETWORK_NAME) {
                const chainId = 0x13881;
                var web3js = new Web3(window.ethereum);
                const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
                if (currentChainId !== chainId) {
                    try {
                        const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: web3js.utils.toHex(chainId) }] });
                        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
                        const tokenContract = new web3js.eth.Contract(tokenContractAbi, TokenContractAddress);
                        const tokenbal = await tokenContract.methods.balanceOf(account[0]).call();
                        const ercbal = await web3js.utils.fromWei(tokenbal, 'ether');
                        setTokenBalance(ercbal)
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                        }
                    }
                }
            } else if (SelectedTokenNetwork == DCHAIN_NETWORK_NAME) {
                const chainId = 0x1498;
                var web3js = new Web3(window.ethereum);
                const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
                if (currentChainId !== chainId) {
                    try {
                        const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: web3js.utils.toHex(chainId) }] });
                        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
                        const tokenContract = new web3js.eth.Contract(tokenContractAbi, TokenContractAddress);
                        const tokenbal = await tokenContract.methods.balanceOf(account[0]).call();
                        const ercbal = await web3js.utils.fromWei(tokenbal, 'ether');
                        setTokenBalance(ercbal)

                    } catch (switchError) {
                        if (switchError.code === 4902) {
                        }
                    }
                } else {
                    console.log("fyugh")
                }
            }
        } catch (error) {

        }
    }

    const formValue = async (e) => {
        const inputValue = e.target.value;
        setTokenAmount(inputValue)
    }


    const ActiveYourPlan = async () => {
        var location = window.location.pathname.split('/')[2];
        try {
            const expectedChainId = SelectedTokenNetwork === BSC_NETWORK_NAME ? BSC_CHAIN_ID : SelectedTokenNetwork === MATIC_NETWORK_NAME ? MATIC_CHAIN_ID : SelectedTokenNetwork === DCHAIN_NETWORK_NAME ? DCHAIN_CHAIN_ID : null;

            if (expectedChainId) {

                const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
                // console.log('---SelectedTokenNetwork', expectedChainId, currentChainId);

                if (currentChainId !== expectedChainId) {
                    // Switch to the expected chain
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: expectedChainId }],
                    });

                } else {
                    const web3js = new Web3(window.ethereum);
                    const account = await window.ethereum.request({ method: 'eth_requestAccounts' });

                    const tokenContract = new web3js.eth.Contract(tokenContractAbi, TokenContractAddress);
                    const fromAccount = (await web3js.eth.getAccounts())[0];
                    const amountInWei = web3js.utils.toWei(tokenAmount);

                    const gasLimit = 2000000;
                    const gasPrice = web3js.utils.toWei('10', 'gwei');

                    // Perform the token transfer
                    const transaction = await tokenContract.methods.transfer(ADMIN_WALLET_ADDRESS, amountInWei).send({
                        from: account[0],
                        gasLimit: gasLimit,
                        gasPrice: gasPrice
                    });
                    console.log('transaction----', transaction);

                    if (transaction.transactionHash) {
                        Swal.fire({
                            title: "Transaction in Progress",
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                                Swal.disableButtons();

                            }
                        });
                    }

                    // const tokenNeedToSend = tokenAmount * TokenPrice;
                    const userDepositedTransaction = await Axios.post(`${API_BACKEND_URL}getTheProjectOwnerTokenAmount`, {
                        transaction: transaction, // Use transactionHash to send to the backend
                        UserProjectRegistedID: location,
                        TokenContractAddress: TokenContractAddress,
                        SelectedTokenNetwork: SelectedTokenNetwork
                    }).then((res) => {
                        console.log('res----', res.data.status);
                        if (res.data.status == true) {
                            Swal.fire({
                                title: 'Transaction',
                                text: 'Transaction Completed',
                                icon: 'success',
                            }).then((receipt) => {
                                if (receipt.isConfirmed) {
                                    // Redirect the user to the desired URL
                                    window.location.href = '/';
                                }
                            });
                        }


                    });
                    console.log('userDepositedTransaction---', userDepositedTransaction);
                }
            } else {
                alert('Invalid network selection');
            }
        } catch (error) {
            // Handle errors here
        }
    };


    useEffect(() => {
        getProjectsDetailsFromDB();


    }, [handleChange()])


    return (
        <div className="App">
            <Navbar />
            <section>
                <div class="container py-5">
                    <p className="ido-text-6 mb-5 text-center">Active Your Plan<img className="star-second-image" src={Star2} alt="star" /></p>

                    <div class="row row-cols-1 row-cols-md-1 g-4  justify-content-center align-items-center ">
                        <div class="col-lg-6 m-0 p-0 card h-100">
                            <div class=" sale">
                                <div class="sale-body-2-active-plan ">
                                    <div className='row row-cols-2 row-cols-1 align-items-center justify-content-between g-4 '>
                                        <div className="col-lg-7 pt-0 mt-0 activeyourplan-input-bg-css">
                                            <div>
                                                <input type="number" class="form-control my-4" placeholder={Tokensymbol + " Token Amount"} aria-label="Username" aria-describedby="basic-addon1" value={tokenAmount} onChange={formValue} />
                                            </div>
                                            <div>
                                                <input type="text" class="form-control my-4" placeholder="Active Plan" aria-label="Username" aria-describedby="basic-addon1" value={Tokensymbol} disabled />
                                            </div>
                                            {/* <div>
                                                <select class="form-select" aria-label="Default select example">
                                                    <option selected>USDT</option>
                                                    <option value="1">BTC</option>
                                                    <option value="2">USDT</option>
                                                    <option value="3">BTC</option>
                                                </select>
                                            </div> */}
                                        </div>
                                        <div className="col-lg-5 pt-0 mt-0">
                                            <button class="btn sales-buy-button" onClick={ActiveYourPlan}>Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card h-100">
                            <div class="sale">
                                <div class="sale-body-2 ">

                                    <p className="contact-text-1 mb-3 text-center">Token Balance: {TokenBalance}</p>
                                    <p className="launch-text-1-activeplan text-center">Token Total Supply: {TokenTotalSupply}</p>
                                    <p className="launch-text-1-activeplan text-center">Token Initial Supply: {TokenInitialSupply}</p>
                                    <p className="launch-text-1-activeplan text-center">Token Price: {TokenPrice}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />

        </div>
    );
}

export default ActiveYourPlan;