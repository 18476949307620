import * as React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import logo from "../images/d-ido.png";
import logo2 from "../images/d-ido-logo.png";
import { SiEclipseche, SiAdguard } from 'react-icons/si';
import { TfiWorld } from 'react-icons/tfi';
import { MdOutlineAdsClick } from 'react-icons/md';
import { IoHome, IoBulbOutline } from 'react-icons/io5';
import { BsLightningChargeFill, BsArrowRight } from 'react-icons/bs';
import { CiMap } from 'react-icons/ci';
import { FaDiscord, FaTelegramPlane } from 'react-icons/fa';
import { GiSellCard } from 'react-icons/gi';
import { GrProjects } from 'react-icons/gr';
import { AiOutlineTeam, AiOutlineInstagram, AiOutlineTwitter, AiFillFacebook, AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import banner from "../images/banner.png"
import Ethereum from "../images/ethereum.png"
import Matic from "../images/matic.png"
import Bnb from "../images/bnb.png"
import BSC from "../images/coin/bnb.png"
import profile from "../images/profile.jpg"

import one from "../images/inner/1.png"
import two from "../images/inner/2.png"
import three from "../images/inner/3.png"

import Star2 from "../images/inner/star.png"
import Star1 from "../images/inner/star1.png"
import separator from "../images/inner/separator.png"
import headingleft from "../images/inner/headingleft.png"
import headingright from "../images/inner/headingright.png"

import character from "../images/team/character.png"
import team1 from "../images/team/team1.png"
import team2 from "../images/team/team2.png"
import team3 from "../images/team/team3.png"
import team4 from "../images/team/team4.png"
import team5 from "../images/team/team5.png"
import team6 from "../images/team/team6.png"

import MarketingManager from "../images/new-team/MarketingManager.png"
import ProjectLeader from "../images/new-team/ProjectLeader.png"
import Head from "../images/new-team/Head.png"
import Manager from "../images/new-team/Manager.png"
import Community from "../images/new-team/Community.png"
import MarketingAdvisor from "../images/new-team/MarketingAdvisor.png"
import Promoter from "../images/new-team/Promoter.png"
import SocialMedia from "../images/new-team/SocialMedia.png"
import writer from "../images/new-team/writer.png"
import Coordinator from "../images/new-team/Coordinator.png"
import Graphic from "../images/new-team/Graphic.png"
import SEO from "../images/new-team/SEO.png"

import spaceman from "../images/spaceman.png"

import polkadot from "../images/partners/polkadot.png"

import infographics from "../images/coinomics/infographics.png"
import Coinomics from "../images/coinomics/Coinomics.png"
import bannertop1 from "../images/banner/banner-top-1.png"
import bannertop from "../images/banner/banner-top.png"
import bannertopparticles from "../images/banner/top-banner-particles.png"

import buggy from "../images/buggy.png"

import facebook from "../images/social/facebook.png"
import github from "../images/social/github.png"
import linkedin from "../images/social/linkedin.png"
import twitter from "../images/social/twitter.png"
import decosystem from "../images/finished-ido/d-ecosystem.png"
import dlogo from "../images/d-ido-logo.png"
import dNft from "../images/finished-ido/d-Nft.png"
import dwallet from "../images/finished-ido/d-wallet.svg"
import box from "../images/box.mp4"

import { HashLink } from 'react-router-hash-link';
import Axios from 'axios';
import FileBase64 from 'react-file-base64';
import Navbar from "../Pages/separate/Navbar";
import Footer from "../Pages/separate/Footer";
import Launch from "../Pages/separate/Launch";
import Svg from "../Pages/separate/Svg";
import { useReactCountdown } from 'use-react-countdown';
import Pie from "./Pie";
import Blog from "./separate/Blog";
import coin from "../images/coinomics/coin.png";
import spaceman12 from "../images/40-ai.png";
import coinomicsdesktop from "../images/coinomics/desktop.png";
import coinomicsmobile from "../images/coinomics/mobile.png";
import Layer1 from "../images/banner/coins/Layer 8.png";
import Layer2 from "../images/banner/coins/Layer 9.png";
import Layer3 from "../images/banner/coins/Layer 10.png";
import Layer4 from "../images/banner/coins/Layer 11.png";
import idolaunchbutton from "../images/banner/ido-launch-button-2.png";
import ProgressBar from 'react-bootstrap/ProgressBar';
import totalraisedimage from '../images/Links/total-raised-image.png'
import axios from "axios"; // axios import here
import { API_URL } from "../config/config";
import Swal from 'sweetalert2';
import $ from "jquery";
import { API_FRONTEND_URL } from '../config/config'
import Idolist from './separate/idolist';

function Phase2() {
    // Date in format (Month DD, YYYY hh:mm:ss)
    const { days, hours, minutes, seconds } = useReactCountdown(
        'May 1, 2023 00:00:00 UTC'
    );

    return (
        <div className="d-flex flex-row gap-3 justify-content-evenly">
            <div className="">
                <span className="ido-text-4 mb-1">{days}</span>
                <span>D</span>
            </div>
            <div className="">
                <span className="ido-text-4 mb-1">{hours}</span>
                <span>H</span>
            </div>
            <div className="">
                <span className="ido-text-4 mb-1">{minutes}</span>
                <span>M</span>
            </div>
            <div className="">
                <span className="ido-text-4 mb-1">{seconds}</span>
                <span>S</span>
            </div>
        </div>
    );
}






function Timerswiper4() {
    // Date in format (Month DD, YYYY hh:mm:ss)
    const { days, hours, minutes, seconds } = useReactCountdown(
        'May 1, 2023 00:00:00 UTC'
    );

    return (
        <div className="d-flex flex-row gap-1 ms-3">
            <div className="">
                <span className="">{days}</span>
                <span>D</span>
            </div>
            <div className="">
                <span className="">{hours}</span>
                <span>H</span>
            </div>
            <div className="">
                <span className="">{minutes}</span>
                <span>M</span>
            </div>
            <div className="">
                <span className="">{seconds}</span>
                <span>S</span>
            </div>
        </div>
    );
}

function Timerswiper5() {
    // Date in format (Month DD, YYYY hh:mm:ss)
    const { days, hours, minutes, seconds } = useReactCountdown(
        'May 1, 2023 00:00:00 UTC'
    );

    return (
        <div className="d-flex flex-row gap-1 ms-3">
            <div className="">
                <span className="">{days}</span>
                <span> days</span>
            </div>
        </div>
    );
}



function Timerswiper7() {
    // Date in format (Month DD, YYYY hh:mm:ss)
    const { days, hours, minutes, seconds } = useReactCountdown(
        'May 1, 2023 00:00:00 UTC'
    );

    return (
        <div className='sales-section-1 d-flex flex-row justify-content-evenly text-center'>
            <div className=''>
                <p className='sales-text-1'>{days}</p>
                <p className='sales-text-2'>days</p>
            </div>
            <div className=''>
                <p className='sales-text-3'>:</p>
            </div>
            <div className=''>
                <p className='sales-text-1'>{hours}</p>
                <p className='sales-text-2'>hours</p>
            </div>
            <div className=''>
                <p className='sales-text-3'>:</p>
            </div>
            <div className=''>
                <p className='sales-text-1'>{minutes}</p>
                <p className='sales-text-2'>minutes</p>
            </div>
            <div className=''>
                <p className='sales-text-3'>:</p>
            </div>
            <div className=''>
                <p className='sales-text-1'>{seconds}</p>
                <p className='sales-text-2'>seconds</p>
            </div>
        </div>
    );
}




class Home extends React.Component {
    constructor(props) {
        super(props);
        this.myDivToFocus = React.createRef()
        this.state = {
            email: "",
            website: "launchpad.d-ecosystem.io",
            emailError: "",
            errors: {},
            amountraised: '',
            amountpercent: ''
        };
    }


    // email handle change functions and API integration
    handleChange = (event) => {
        this.setState({
            email: event.target.value
        });
        this.validationcheck(event.target.value);
    }
    validationcheck = (value) => {
        let isValid = true;
        let emailReg = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
        if (value == "") {
            this.setState({
                emailError: "Email field is required"
            });
            isValid = false
        } else if (this.state.email == "" || this.state.email === undefined) {
            this.setState({
                emailError: "Email field is required"
            });
            isValid = false
        } else if (!emailReg.test(this.state.email)) {
            this.setState({
                emailError: "Email Address does not appear to be valid"
            });
            isValid = false
        } else {
            this.setState({ emailError: "" });
        }
        return isValid
    }


    handleSubmit = (event) => {
        event.preventDefault();
        var validstatus = this.validationcheck();
        if (validstatus) {
            axios({
                method: "POST",
                url: `${API_URL}newsletter/add`,
                data: {
                    email: this.state.email,
                    website: this.state.website
                }
            }).then((data) => {
                if (data.data) {
                    this.setState({ email: "" });
                }
                Swal.fire({
                    // title: "Wow!",
                    text: "Thanks For Joining our Community!!",
                    icon: "success",
                });
            }).catch((err) => console.log(err))
        }
    }

    componentDidMount() {
        this.getamountraised()
    }

    getamountraised = async () => {
        const amountraisedget = await axios.get(`${API_URL}/getamountraised`)
        const setamountraised = amountraisedget.data
        if (setamountraised >= 1000000) {
            const setamountpercent = (setamountraised / 2000000) * 100
            $('#phase1hardtarget2').show();
            this.setState({
                amountraised: setamountraised,
                amountpercent: setamountpercent
            })
        } else {
            $('#phase1hardtarget2').hide();
            const setamountpercent = (setamountraised / 1000000) * 100
            this.setState({
                amountraised: setamountraised,
                amountpercent: setamountpercent
            })
        }
    }


    render() {
        return (
            <div className="App">
                <Navbar />
                {/* <Launch /> */}
                <Svg />
                {/* <img src={buggy} alt="glow" className="banner-top-fixed-buggy-rool-image" /> */}
                <img src={bannertop} alt="glow" className="banner-top-fixed-images" />
                <img src={bannertopparticles} alt="glow" className="banner-top-fixed-images-particles-1 bonben-animation-2" />
                <img src={bannertopparticles} alt="glow" className="banner-top-fixed-images-particles-2 bonben-animation-2" />

                <img src={Layer1} alt="glow" className="banner-coin-fixed-images-1 bonben-animation-coin" />
                <img src={Layer2} alt="glow" className="banner-coin-fixed-images-2 bonben-animation-coin-2" />
                <img src={Layer3} alt="glow" className="banner-coin-fixed-images-3 bonben-animation-coin-2" />
                <img src={Layer4} alt="glow" className="banner-coin-fixed-images-4 bonben-animation-coin" />

                <div class="hero-section-banner overflow-hidden" id="home">
                    <div class="container">
                        <div class="row align-items-center g-4 hero-section-banner-height flex-lg-row-reverse justify-content-around">
                            <div class="col-lg-6">
                                <img className="banner-top-images bonben-animation" src={bannertop1} alt="blockchain-home-img-1" />
                            </div>
                            <div class="col-lg-6">
                                {/* <a href="https://dcxlaunchpad.d-ecosystem.io/" target="_blank"><img src={idolaunchbutton} alt="idolaunchbutton" className='idolaunchbutton-banner' /></a> */}
                                <div className='idolaunchtext-banner pt-lg-5 mt-lg-5'>
                                    <h1 class="mt-3 banner-top-text-1">Welcome to <br/>D-Launchpad<br/> Igniting Innovation in the Crypto Space!</h1>
                                    <h5 className="ido-text-1 mt-4">World’s first cross chain crowdfunding platform</h5>
                                    <p class=" mt-3 ido-text-2">Beyond being a crowdfunding platform, we're a vibrant community comprising crypto enthusiasts, developers, and investors. </p>
                                    {/* <a href="/launchin" target="_blank" class="get-start-1">Join Sales</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='new-banner-swiper container'>
                <p className="ido-text-3 mb-3 mt-4">
                            <img className="me-lg-3 fade-left-img" src={headingleft} alt="arrow" />
                            RECENT
                            <img className="ms-lg-3 fade-left-img" src={headingright} alt="arrow" />
                        </p>
                        <p className="ido-text-6 mb-4">Active IDOs<img className="star-second-image" src={Star2} alt="star" /></p>
                    <Idolist />
                </div>

                {/* <div class="two-tabs-section-banner pb-5">
                    <div class="container">
                        <div class="row align-items-center g-4">
                            <ul class="nav nav-pills mb-3 sales-tabs-section" id="pills-tab" role="tablist">

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link " id="pills-PreSales-tab" data-bs-toggle="pill" data-bs-target="#pills-PreSales" type="button" role="tab" aria-controls="pills-PreSales" aria-selected="false">Presale</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-idophase1-tab" data-bs-toggle="pill" data-bs-target="#pills-idophase1" type="button" role="tab" aria-controls="pills-idophase1" aria-selected="false">IDO Phase 1</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-idophase2-tab" data-bs-toggle="pill" data-bs-target="#pills-idophase2" type="button" role="tab" aria-controls="pills-idophase2" aria-selected="false">IDO Phase 2</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">

                                <div class="tab-pane fade show active" id="pills-idophase1" role="tabpanel" aria-labelledby="pills-idophase1-tab" tabindex="0">
                                    <div class="sales-tab-content-section">
                                        <div class="container">
                                            <div class="row row-cols-1 row-cols-md-1 g-4 justify-content-center align-items-center ">
                                                <div class="col-lg-8 m-0 p-0">
                                                    <div class="sale">
                                                        <div class="sale-body-2 ">
                                                            <div className='sales-section-conf-bg-1 row align-items-center justify-content-between'>
                                                                <div className='col'>
                                                                    <img src={totalraisedimage} className='total-raised-image-styling' />
                                                                </div>
                                                                <div className='col '>
                                                                    <div className='text-center align-items-center'>
                                                                        <div className='mb-0'>
                                                                            <span className='sales-text-5 fw-bold '>TOTAL FUND RAISED: </span>
                                                                            <p className='fs-30 mb-0'>$ 6.5M</p>
                                                                        </div>
                                                                        <div>
                                                                            <a href="https://sales.d-ecosystem.io/" target="_blank" type="button" class="btn sales-buy-button">Join Sale</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}




                <div class="eight-section-banner  py-5">
                    <div class="container py-5">
                        <div class="row row-cols-1 row-cols-md-1 g-4">
                            <div class="col">
                                <div class="card h-100 py-4">
                                    <div class="card-body text-center mx-auto">
                                        <p className="contact-text-1 mb-3 text-center">About Us <img className="star-second-image" src={Star2} alt="star" /></p>
                                        <p className="launch-text-1">At D-Launchpad, we believe in the power of decentralized innovation with Cross chain Functions, and we're here to fuel the next wave of groundbreaking crypto projects. As a cutting-edge crowdfunding platform, D-Launchpad connects visionary creators with a global community of supporters, empowering them to bring their ideas to life.</p>
                                        {/* <a href="/idoform" target="_blank" class="get-start-1 mt-3">Apply to launch your IDO</a> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="advantage-section-banner py-5">
                    <div class="container">

                        <div class="row g-4 align-items-center justify-content-around flex-lg-row-reverse">
                            <div class="col-lg-5">
                                <img src={spaceman12} className="bonben-animation" alt="spaceman12" />
                            </div>
                            <div class="col-lg-5">
                                <p className="contact-text-1 mb-3">Why Choose D-Launchpad? <img className="star-second-image" src={Star2} alt="star" /></p>
                                <div class="d-flex flex-row mb-3">
                                    <div class="">
                                        <SiEclipseche className='launch-icon-1' />
                                    </div>
                                    <div class="">
                                        <p class="launch-text-2 mb-2">Decentralized Multichain Funding</p>
                                        <p class="launch-text-3">D-Launchpad harnesses the strength of the blockchain to provide a decentralized funding model that supports to raise funds from all chain. Say goodbye to traditional financing hurdles; our platform opens doors for projects of all sizes.</p>
                                    </div>
                                </div>
                                <div class="d-flex flex-row mb-3">
                                    <div class="">
                                        <MdOutlineAdsClick className='launch-icon-1' />
                                    </div>
                                    <div class="">
                                        <p class="launch-text-2 mb-2">Community-Driven Support</p>
                                        <p class="launch-text-3">We're more than just a crowdfunding platform; we're a community of crypto enthusiasts, developers, and investors. Join us in shaping the future of the blockchain by supporting projects that align with your vision.</p>
                                    </div>
                                </div>
                                <div class="d-flex flex-row mb-3">
                                    <div class="">
                                        <SiAdguard className='launch-icon-1' />
                                    </div>
                                    <div class="">
                                        <p class="launch-text-2 mb-2">Secure and Transparent</p>
                                        <p class="launch-text-3">Trust is at the core of D-Launchpad. Our smart contract technology ensures a secure and transparent funding process, giving backers confidence in the projects they support.</p>
                                    </div>
                                </div>
                                <div class="d-flex flex-row">
                                    <div class="">
                                        <GrProjects className='launch-icon-1' />
                                    </div>
                                    <div class="">
                                        <p class="launch-text-2 mb-2">Diverse Project Categories</p>
                                        <p class="launch-text-3">From decentralized finance (DeFi) and non-fungible tokens (NFTs) to blockchain infrastructure and innovative applications, D-Launchpad welcomes projects from a wide range of categories.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="third-section-banner py-5">
                    <div class="container-fluid col-lg-10">
                        {/* <p className="ido-text-3 mb-3">
                            <img className="me-lg-3 fade-left-img" src={headingleft} alt="arrow" />
                            3 EASY STEPS
                            <img className="ms-lg-3 fade-left-img" src={headingright} alt="arrow" />
                        </p> */}
                        <p className="ido-text-6 mb-4">How It Works<img className="star-second-image" src={Star2} alt="star" /></p>
                        <div class="row g-4" id="usecases">
                            <div class="col-lg-3">
                                <div className="d-flex flex-row gap-3">
                                    <h4 className='roadmap-text-1'>1</h4>
                                    {/* <div className="steps-section-image"><img src={one} alt="ido" /></div> */}
                                    <div className="">
                                        <h5 className="ido-text-7">Project Submission</h5>
                                        <p className="ido-text-8 mb-0">Connect D-Wallet or MetaMask Wallet</p>
                                        <p className="ido-text-8">   Creators submit their projects, detailing their vision, goals, and the impact they aim to make in the crypto space.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div className="d-flex flex-row gap-3">
                                    <h4 className='roadmap-text-1'>2</h4>
                                    {/* <div className="steps-section-image"><img src={two} alt="ido" /></div> */}
                                    <div className="">
                                        <h5 className="ido-text-7">Community Backing</h5>
                                        <p className="ido-text-8 mb-0">The D-Launchpad community has the power to support projects they believe in. Backers receive exclusive rewards and early access to project developments.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div className="d-flex flex-row gap-3">
                                    <h4 className='roadmap-text-1'>3</h4>
                                    {/* <div className="steps-section-image"><img src={three} alt="ido" /></div> */}
                                    <div className="">
                                        <h5 className="ido-text-7">Funding Milestones</h5>
                                        <span className="ido-text-8">Projects set funding milestones, and as each milestone is achieved, they gain the resources needed to move forward in their development journey.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div className="d-flex flex-row gap-3">
                                    <h4 className='roadmap-text-1'>4</h4>
                                    {/* <div className="steps-section-image"><img src={three} alt="ido" /></div> */}
                                    <div className="">
                                        <h5 className="ido-text-7">Funding Milestones</h5>
                                        <span className="ido-text-8">Projects set funding milestones, and as each milestone is achieved, they gain the resources needed to move forward in their development journey.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="eight-section-banner why-d-launchpad-section py-5">
                    <div class="container py-5">
                        <div class="row row-cols-1 row-cols-md-1 g-4">
                            <div class="col">
                                <div class="card h-100 py-4">
                                    <div class="card-body text-center mx-auto">
                                        <p className="contact-text-1 mb-3 text-center">Join the Revolution <img className="star-second-image" src={Star2} alt="star" /></p>
                                        <p className="launch-text-1">Whether you're a visionary creator with a groundbreaking idea, an investor looking for the next big thing, or a crypto enthusiast eager to be part of the revolution, D-Launchpad welcomes you.</p>
                                        <a href="/idoform" target="_blank" class="get-start-1 mt-3">Ready to Launch?</a>
                                        <a href="/idolaunch" target="_blank" class="get-start-1 mt-3 ms-3">Ready to Invest?</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div class="fourth-section-banner py-5">
                    <div class="container">
                        <p className="ido-text-3 mb-3">
                            <img className="me-lg-3 fade-left-img" src={headingleft} alt="arrow" />
                            RECENT
                            <img className="ms-lg-3 fade-left-img" src={headingright} alt="arrow" />
                        </p>
                        <p className="ido-text-6 mb-4">Active IDOs<img className="star-second-image" src={Star2} alt="star" /></p>
                        <div class="row row-cols-1 row-cols-md-3 g-4">
                            {this.state.volumepath.map((token) =>
                                <div class="col">
                                    <Link to={`/activeido/${token.name}`} state={{ from: this.state.volumepath }}>
                                        <div class="card h-100">
                                            <div class="card-body px-0">
                                                <div className="d-flex flex-row gap-3 px-3 mb-3">
                                                    <div className="steps-section-image-launch"><img src={profile} className="" alt="logo" /></div>
                                                    <div className="">
                                                        <p className="ido-text-9 mb-0">{token.name}</p>
                                                        <p className="ido-text-10 mb-1">Price USD : {token.tokenprice} usd</p> </div>
                                                </div>
                                                <div className="d-flex flex-row gap-3 mt-2 align-items-center">
                                                    <div className="left-days-card-section"><span className="ido-text-10">{token.enddate}</span></div>
                                                    <div className="ms-auto me-3">
                                                        <img style={{ width: "35px" }} src={token.image} alt="logo" />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-3 px-2 mt-4">
                                                    <div className="steps-section-image">
                                                        <p className="ido-text-11 mb-1">TOKEN SUPPLY</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <p className="ido-text-12 mb-1">{token.tokensupply}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                    <div className="steps-section-image">
                                                        <p className="ido-text-11 mb-1">INITIAL SUPPLY</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <p className="ido-text-12 mb-1">{token.initialsupply}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                    <div className="steps-section-image">
                                                        <p className="ido-text-11 mb-1">TOKEN PRICE</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <p className="ido-text-12 mb-1">{token.tokenprice}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                    <div className="steps-section-image">
                                                        <p className="ido-text-11 mb-1">HARD CAP</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <p className="ido-text-12 mb-1">{token.hardcapvalue}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row gap-3 px-2 mt-2 mb-3">
                                                    <div className="steps-section-image">
                                                        <p className="ido-text-11 mb-1">SOCIAL</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <p className="ido-text-12 mb-1 d-flex">
                                                            <AiOutlineTwitter className="ido-text-13" />
                                                            <FaDiscord className="ido-text-13" />
                                                            <AiOutlineInstagram className="ido-text-13" />
                                                            <AiFillFacebook className="ido-text-13" />
                                                            <FaTelegramPlane className="ido-text-13" />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div> */}




                {/* <div class="sixth-section-banner py-5">
                    <div class="container-fluid">
                        <div class="row g-4 justify-content-around" id="roadmap">
                            <div class="col-lg-4">
                                <img className="roadmap-image-2 bonben-animation d-lg-block d-none" src={spaceman} alt="ido" />
                            </div>
                            <div class="col-lg-8">
                                <p className="ido-text-6 mb-5 ps-5">ROADMAP<img className="star-second-image" src={Star2} alt="star" /></p>
                                <Swiper
                                    className="roadmap-swiper"
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    breakpoints={{
                                        "@0.00": {
                                            slidesPerView: 1,
                                            spaceBetween: 10,
                                        },
                                        "@0.75": {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        "@1.00": {
                                            slidesPerView: 3,
                                            spaceBetween: 40,
                                        },
                                        "@1.50": {
                                            slidesPerView: 3,
                                            spaceBetween: 50,
                                        },
                                    }}
                                    navigation={true} modules={[Navigation]} >
                                    <SwiperSlide className="ps-5">
                                        <div className="roadmap-image-1">
                                            <img src={separator} alt="roadmap" />
                                        </div>
                                        <span className="roadmap-text-1">Q1  - 2023</span>
                                        <ul className="mt-3">
                                            <li className="ido-text-11 mb-1">Beta release & Development of Validator Tool</li>
                                            <li className="ido-text-11 mb-1">Stable Coin development</li>
                                            <li className="ido-text-11 mb-1">Swap Module Development</li>
                                            <li className="ido-text-11 mb-1">D-Swap Launch</li>
                                            <li className="ido-text-11 mb-1">Yield Farming Launch</li>
                                            <li className="ido-text-11 mb-1">IDO Launch</li>
                                            <li className="ido-text-11 mb-1">DEX Testnet</li>
                                            <li className="ido-text-11 mb-1">Stable Coin Beta Launch</li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide className="ps-5">
                                        <div className="roadmap-image-1">
                                            <img src={separator} alt="roadmap" />
                                        </div>
                                        <span className="roadmap-text-1">Q2  -2023</span>
                                        <ul className="mt-3">
                                            <li className="ido-text-11 mb-1">Stable Coin updates</li>
                                            <li className="ido-text-11 mb-1">Web 3 Updates for Metaverse and Compatible Check</li>
                                            <li className="ido-text-11 mb-1">Launchpad Development for External DeFi projects</li>
                                            <li className="ido-text-11 mb-1">Cross Chain DEX Development</li>
                                            <li className="ido-text-11 mb-1">Addition and Implementation of more Chains into the Platform</li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide className="ps-5">
                                        <div className="roadmap-image-1">
                                            <img src={separator} alt="roadmap" />
                                        </div>
                                        <span className="roadmap-text-1">Q3 - 2023</span>
                                        <ul className="mt-3">
                                            <li className="ido-text-11 mb-1">Development of VM Compatible to Messenger P2P and Security</li>
                                            <li className="ido-text-11 mb-1">Development of Messenger and P2P Module</li>
                                            <li className="ido-text-11 mb-1">Updates and Security Updates according to EVM</li>
                                            <li className="ido-text-11 mb-1">Development and Launch of Android, iOS and Web DeFi Store for Non-Custodial Community</li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide className="ps-5">
                                        <div className="roadmap-image-1">
                                            <img src={separator} alt="roadmap" />
                                        </div>
                                        <span className="roadmap-text-1">Q4 - 2023</span>
                                        <ul className="mt-3">
                                            <li className="ido-text-11 mb-1">Security updates</li>
                                            <li className="ido-text-11 mb-1">D-Swap Stop Loss and Slippage Standardization</li>
                                            <li className="ido-text-11 mb-1">Multi Wallet Connecting System</li>
                                            <li className="ido-text-11 mb-1">Multi Wallet Connecting System IGO</li>
                                            <li className="ido-text-11 mb-1">Community Forum Platform</li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide className="ps-5">
                                        <div className="roadmap-image-1">
                                            <img src={separator} alt="roadmap" />
                                        </div>
                                        <span className="roadmap-text-1">Q1 - 2024</span>
                                        <ul className="mt-3">
                                            <li className="ido-text-11 mb-1">Launch of Decentralized Encashing System(P2P Platform) </li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide className="ps-5">
                                        <div className="roadmap-image-1">
                                            <img src={separator} alt="roadmap" />
                                        </div>
                                        <span className="roadmap-text-1">Q2-2024</span>
                                        <ul className="mt-3">
                                            <li className="ido-text-11 mb-1">D- wallet - Incognito Mode Update & Additional Security Feature Update</li>
                                        </ul>
                                    </SwiperSlide>
                                    <SwiperSlide className="ps-5">
                                        <div className="roadmap-image-1">
                                            <img src={separator} alt="roadmap" />
                                        </div>
                                        <span className="roadmap-text-1">Q3- 2024</span>
                                        <ul className="mt-3">
                                            <li className="ido-text-11 mb-1">Launch of Fully Functional Metaverse Platform</li>
                                        </ul>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div class=" py-5" id="coinomics">
                    <div class="container">
                        <p className="ido-text-6 mb-5 text-center">Coinomics<img className="star-second-image" src={Star2} alt="star" /></p>

                        <div class="row g-4 justify-content-around">
                            <div class="col-lg-8">
                                <img className="" src={Coinomics} alt="ido" />
                                <Pie />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div class=" py-5" id="vesting">
                    <div class="container">
                        <p className="ido-text-6 mb-5 text-center">COIN ALLOCATION & VESTING<img className="star-second-image" src={Star2} alt="star" /></p>

                        <div class="row g-4 align-items-center mt-5 ">
                            <div className='col'>
                                <img className='d-lg-block d-none' src={coinomicsdesktop} alt="" />

                                <img className='d-lg-none d-block' src={coinomicsmobile} alt="" />
                            </div>
                            <div class="col-lg-4">
                                <div className='vesting-section-card vesting-1'>
                                    <p className='vest-text-1'>PUBLIC SALE - 33.0% </p>
                                    <p className='vest-text-2'>173,976,134</p>
                                    <p className='vest-text-2'>PUBLIC SALE, 2023 RELEASES:</p>
                                    <ul className='mt-2'>
                                        <li className='vest-text-3'>Q1: 33.334%</li>
                                        <li className='vest-text-3'>Q2: 33.334%</li>
                                        <li className='vest-text-3'>Q3: 33.332%</li>
                                    </ul>

                                </div>

                                <div className='mt-5 vesting-section-card vesting-2'>
                                    <p className='vest-text-1'>EARLY FOUNDER - 15.7% </p>
                                    <p className='vest-text-2'>82,770,464</p>
                                    <p className='vest-text-2'>EARLY FOUNDERS RELEASE 10% </p>
                                    <ul className='mt-2'>
                                        <li className='vest-text-3'>FROM Q4-2023</li>
                                        <li className='vest-text-3'>TO Q1-2026</li>
                                    </ul>

                                </div>

                                <div className='mt-5 vesting-section-card vesting-3'>
                                    <p className='vest-text-1'>ADVISORS - 4.3% </p>
                                    <p className='vest-text-2'>22,669,617</p>
                                    <p className='vest-text-2'>ADVISORS RELEASE 10%</p>
                                    <ul className='mt-2'>
                                        <li className='vest-text-3'>FROM Q4-2023</li>
                                        <li className='vest-text-3'>TO Q1-2026</li>
                                    </ul>
                                </div>
                                <div className='mt-5 vesting-section-card vesting-4'>
                                    <p className='vest-text-1'>STACKING REWARDS - 10.0% </p>
                                    <p className='vest-text-2'>52,720,041</p>
                                    <p className='vest-text-2'>25% EACH IN Q2-2023</p>
                                    <ul className='mt-2'>
                                        <li className='vest-text-3'>Q1-2024, Q4-2024, AND Q3-2025</li>
                                    </ul>

                                </div>
                            </div>


                            <div class="col-lg-4 ">


                                <div className='mt-5 vesting-section-card vesting-5'>
                                    <p className='vest-text-1'>TEAM - 10.0% </p>
                                    <p className='vest-text-2'> 52,720,041</p>
                                    <p className='vest-text-2'>TEAM MEMBERS WILL RELEASE 10% </p>
                                    <ul className='mt-2'>
                                        <li className='vest-text-3'>FROM Q4-2023</li>
                                        <li className='vest-text-3'>TO Q1-2026</li>
                                    </ul>

                                </div>

                                <div className='mt-5 vesting-section-card vesting-6'>
                                    <p className='vest-text-1'>FOUNDATION - 5.0% </p>
                                    <p className='vest-text-2'>26,360,020</p>
                                    <p className='vest-text-2'>FOUNDATION WILL BE RELEASED IN:</p>
                                    <ul className='mt-2'>
                                        <li className='vest-text-3'>Q2 & Q4-2023: 10%</li>
                                        <li className='vest-text-3'>10% EACH IN Q2-2024</li>
                                        <li className='vest-text-3'>Q4-2024, Q2-2025,</li>
                                        <li className='vest-text-3'>Q4-2025, Q1-2026:40%</li>
                                    </ul>
                                </div>

                                <div className='mt-5 vesting-section-card vesting-7'>
                                    <p className='vest-text-1'>ECOSYSTEM - 22.0%</p>
                                    <p className='vest-text-2'>115,984,090</p>
                                    <p className='vest-text-2'>ECOSYSTEM WILL BE RELEASED IN:</p>
                                    <ul className='mt-2'>
                                        <li className='vest-text-3'>10% EACH IN Q3-2023,Q1-2024, Q3-2024,<br /> Q1-2025, Q3-202501-2026: 50%</li>
                                    </ul>
                                    <p className='vest-text-2'>AIRDROP -5% (26,360,021)</p>
                                    <p className='vest-text-2'>ECOSYSTEM DEVELOPMENT -17% (89,624,069)</p>
                                </div>
                            </div>

                            <div class="col-lg-4 ">
                                <img src={coin} className="coinomics-image-1" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* 
                <div class="seventh-section-banner pb-5" id="team">
                    <div class="container" id="team">
                        <p className="ido-text-3 mb-3 text-center">
                            <img className="me-lg-3 fade-left-img" src={headingleft} alt="arrow" />
                            TEAM MEMBERS
                            <img className="ms-lg-3 fade-left-img" src={headingright} alt="arrow" />
                        </p>
                        <p className="ido-text-6 mb-5 text-center">MEET THE CREW<img className="star-second-image" src={Star2} alt="star" /></p>
                        <div class="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={ProjectLeader} alt="character" />
                                        <h5 className="team-text-1 mt-4">Wally Walrus</h5>
                                        <h5 className="team-text-2 mt-2">Project Leader/ Founder</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={Head} alt="character" />
                                        <h5 className="team-text-1 mt-4">Chuckles McFunny</h5>
                                        <h5 className="team-text-2 mt-2">Developing Head</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={Manager} alt="character" />
                                        <h5 className="team-text-1 mt-4">Binky Bubblegum</h5>
                                        <h5 className="team-text-2 mt-2">Project Manager</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={Community} alt="character" />
                                        <h5 className="team-text-1 mt-4">Fanny Flamenco</h5>
                                        <h5 className="team-text-2 mt-2">Community /Marketing Manager</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={MarketingManager} alt="character" />
                                        <h5 className="team-text-1 mt-4">Buddy Biscuit</h5>
                                        <h5 className="team-text-2 mt-2">Marketing Manager (Europe)</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={MarketingAdvisor} alt="character" />
                                        <h5 className="team-text-1 mt-4">Pippin Pudding</h5>
                                        <h5 className="team-text-2 mt-2">Marketing Advisor</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={Promoter} alt="character" />
                                        <h5 className="team-text-1 mt-4">Mimi Marshmallow</h5>
                                        <h5 className="team-text-2 mt-2">Marketing Promoter</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={SocialMedia} alt="character" />
                                        <h5 className="team-text-1 mt-4">Ziggy Zucchini</h5>
                                        <h5 className="team-text-2 mt-2">Social Media Manager</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={writer} alt="character" />
                                        <h5 className="team-text-1 mt-4">Benny Bounce</h5>
                                        <h5 className="team-text-2 mt-2">Article writer</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={Coordinator} alt="character" />
                                        <h5 className="team-text-1 mt-4">Snickersnack Snickerdoodle</h5>
                                        <h5 className="team-text-2 mt-2">Team Coordinator</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={Graphic} alt="character" />
                                        <h5 className="team-text-1 mt-4">Chucklehead Charlie</h5>
                                        <h5 className="team-text-2 mt-2">Graphic Design</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card h-100 team-card">
                                    <div class="card-body text-center">
                                        <img src={SEO} alt="character" />
                                        <h5 className="team-text-1 mt-4">Hilarious Henry</h5>
                                        <h5 className="team-text-2 mt-2">SEO analyst</h5>
                                        <div className="mb-1 d-flex justify-content-center mt-4">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem" ><AiOutlineTwitter className="team-text-3" /></a>
                                            <a href="https://www.linkedin.com/in/d-wallet-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillLinkedin className="team-text-3" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer" ><AiFillFacebook className="team-text-3" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="team-text-3" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}


                <div class="two-tabs-section-banner pb-5">

                    <div class="container">
                        <div class="row row-cols-1 row-cols-md-1 g-4 justify-content-center align-items-center ">
                            <div class="col-lg-8 m-0 p-0">
                                <div class="sale" style={{backgroundImage:"none"}}>
                                    <div class="sale-body-2 " style={{backgroundImage:"none"}}>
                                        <div className='sales-section-conf-bg-1 row align-items-center justify-content-between' style={{backgroundImage:"none"}}>
                                            <div className='col '>
                                                <div className='text-center align-items-center'>
                                                    <div className='mb-0'>
                                                        <p className="launch-text-1">Dive into the world of decentralized innovation and be a part of projects that shape the future of crypto. Join D-Launchpad, where ideas take flight, and the crypto community comes together to make history.</p>
                                                        <a href="/idolaunch" target="_blank" class="get-start-1 mt-3">
                                                            Start Exploring Projects Now!
                                                        </a>
                                                    </div>
                                                    {/* <div>
                                                                            <a href="https://sales.d-ecosystem.io/" target="_blank" type="button" class="btn sales-buy-button">Join Sale</a>
                                                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>




                <section className="banner-section-7 py-5">
                    <div className='container'>
                        <p className="ido-text-6 mb-5 ms-3">Blog<img className="star-second-image" src={Star2} alt="star" /></p>
                        <Blog />
                    </div>
                </section>

                <section className="banner-section-8 py-5 position-relative overflow-hidden">
                    <div className="container-fluid">
                        <video className='banner-video-bottom-1' autoPlay loop muted>
                            <source src={box} type="video/mp4" />
                        </video>
                        <div className="container">
                            <div class="row">
                                <div class="col-lg-5">
                                    <p className="stay-mail-text-1">STAY UP TO DATE</p>
                                    <p className="stay-mail-text-2">Get our newsletter</p>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your mail"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            />
                                            <span className="input-group-text" id="basic-addon1"   >
                                                <button
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        border: "none",
                                                        cursor: "pointer",
                                                        overflow: "hidden",
                                                        outline: "none",
                                                        backgroundRepeat: "no-repeat"
                                                    }} type="submit" >
                                                    <BsArrowRight style={{ color: "white" }} />
                                                </button>
                                            </span>
                                        </div>
                                        <span className="" style={{ color: "red" }}>{this.state.emailError}</span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default Home;
