export const API_URL = 'https://panel.d-ecosystem.io/';
export const API_BACKEND_URL = 'https://launchpad-api.d-ecosystem.io/';
export const API_FRONTEND_URL = 'https://launchpad.d-ecosystem.io/';
// export const API_BACKEND_URL = 'http://localhost:5000/';
// export const API_FRONTEND_URL = 'http://localhost:3000/';
export const ADMIN_WALLET_ADDRESS = '0x676Af14458149688Ab97514AA2Ae4Ab0BBD05718';
export const BSC_NETWORK_NAME = 'BSC';
export const MATIC_NETWORK_NAME = 'MATIC';
export const DCHAIN_NETWORK_NAME = 'D-CHAIN';
export const BSC_CHAIN_ID = "0x61" ;
export const MATIC_CHAIN_ID = "0x13881" ;
export const DCHAIN_CHAIN_ID = "0x1498" ;
export const MATIC_HEX_CHAIN_ID = '80001';
export const D_LAUNCH_PAD_CONTRACT_ADDRESS = '0x913f76AC3792BeA3dd63a928e3E4afE078F8bc8A';
export const USDT_TOKEN_ADDRESS = '0x5acf2ab8258ee26e37d7583ed9e92d60240e32fb';
export const BUSD_TOKEN_ADDRESS = '0x537be976de3c8ac4a89378ffb93717f179299915';

// client given token Address 0x537be976de3c8ac4a89378ffb93717f179299915