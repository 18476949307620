import React, { useEffect, useState } from "react";
import {
    Link
} from "react-router-dom";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsArrowUpRightCircle } from 'react-icons/bs';
// import Blog1 from "../../Assests/images/blog/blog1.jpg"
// import Blog2 from "../../Assests/images/blog/blog2.jpg"
// import Blog3 from "../../Assests/images/blog/blog3.jpg"
// import Blog4 from "../../Assests/images/blog/blog4.jpg"
// import Blog5 from "../../Assests/images/blog/blog5.jpg"
// import Blog6 from "../../Assests/images/blog/blog6.jpg"

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/effect-cards";
import BSC from "../../images/coin/bnb.png"
import profile from "../../images/profile.jpg"

import { FaDiscord, FaTelegramPlane } from 'react-icons/fa';
import { AiOutlineTeam, AiOutlineInstagram, AiOutlineTwitter, AiFillFacebook, AiOutlineSearch } from 'react-icons/ai';
// import blog from "../../Assests/images/banner/Blog/Blog.png"
import axios from "axios";
// import required modules
import { Navigation } from "swiper";
//import { API_URL } from "../../config/config";
import { API_BACKEND_URL } from '../../config/config';
import tokenlogo from '../../images/toptoken_logo.png';
class Idolist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeProjectsState: [],
            noactiveprojectsData: true
        }
    }

    componentDidMount() {
        this.getido();
    }

    formatDateTime = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    getido = async () => {
        const getdetails = await axios.get(`${API_BACKEND_URL}getIdoData`);
        const launchPad = getdetails.data.idoapp;
        // console.log('launchPad----', launchPad);
        const todayDate = new Date();

        const activeProjects = [];

        for (var i = 0; i < launchPad.length; i++) {
            const endDate = new Date(launchPad[i].enddate);
            const startDate = new Date(launchPad[i].startdate);
            const formattedStartDateTime = this.formatDateTime(startDate);
            const formattedEndDateTime = this.formatDateTime(endDate);
            const todayDateAndTime = this.formatDateTime(todayDate);
            // console.log('launchPad----', launchPad);
            if (todayDate >= startDate && todayDate <= endDate) {
                this.setState({
                    noactiveprojectsData: false,
                });
                // Active project
                const project = {
                    _id: launchPad[i]._id,
                    name: launchPad[i].name,
                    description: launchPad[i].description,
                    tokensupply: launchPad[i].tokensupply,
                    initialsupply: launchPad[i].initialsupply,
                    tokenprice: launchPad[i].tokenprice,
                    hardcapvalue: launchPad[i].hardcapvalue,
                    projectname: launchPad[i].projectname,
                    blockchainvalues: launchPad[i].blockchainvalues,
                    startdate: formattedStartDateTime,
                    enddate: formattedEndDateTime,
                    image: BSC,
                    tokenname: launchPad[i].tokenname,
                    tokensymbol: launchPad[i].tokensymbol,
                    tokendecimal: launchPad[i].tokendecimal,
          
                };
                activeProjects.push(project);
            } 
        }

        // Set the state for active, upcoming, and completed projects
        this.setState({
            activeProjectsState: activeProjects,
        });


    }


    render() {

        return (
        <div>
            <div className="container" id="blog">
                <div class="row justify-content-center">
                  
                    { this.state.noactiveprojectsData?<div className="row">
                        <div class="col-lg-4">
                                <div class="card h-100" >
                                    <div class="card-body px-0">
                                        <div style={{height:"200px"}} className="d-flex align-items-center justify-content-center">
                                        <p>Coming Soon</p>
                                        </div>
                                    </div>
                                </div>
                        </div>


                        <div class="col-lg-4 d-lg-block d-none">
                                <div class="card h-100" >
                                    <div class="card-body px-0">
                                        <div style={{height:"200px"}} className="d-flex align-items-center justify-content-center">
                                        <p>Coming Soon</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-lg-4 d-lg-block d-none">
                                <div class="card h-100" >
                                    <div class="card-body px-0">
                                        <div style={{height:"200px"}} className="d-flex align-items-center justify-content-center">
                                        <p>Coming Soon</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div> : false}
                    

                    <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper "
                        spaceBetween={80}
                        breakpoints={{
                            "@0.00": {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            "@0.75": {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            "@1.00": {
                                slidesPerView: 1,
                                spaceBetween: 40,
                            },
                            "@1.50": {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                    >
                        {
                            this.state.activeProjectsState.map((active) => {
                                return (


                                    <SwiperSlide >
                                        <div class="col">
                                        <Link to={`/activeido/${active._id}`} state={{ from: this.state.activeProjectsState }}>
                                        {/* <Link to={`https://buyidolauchpad.d-ecosystem.io/`} state={{ from: this.state.activeProjectsState }}> */}

                                       
                                                <div class="card h-100">
                                                    <div class="card-body px-0">
                                                        <div className="d-flex flex-row gap-3 px-3 mb-3">
                                                            <div className="steps-section-image-launch"><img src={tokenlogo} className="" alt="logo" /></div>
                                                            <div className="">
                                                                <p className="ido-text-9 mb-0">{active.projectname}</p>
                                                                <p className="ido-text-10 mb-1">Price USD : {active.tokenprice} usd</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row gap-3 mt-2 align-items-center">
                                                            <div className="left-days-card-section">LAUNCH ON <span className="ido-text-10"> {active.startdate}</span></div>
                                                            {/* <div className="ms-auto me-3">
                                                                <img style={{ width: "35px" }} src={active.image} alt="logo" />
                                                            </div> */}
                                                        </div>
                                                        <div className="d-flex flex-row gap-3 px-2 mt-4">
                                                            <div className="steps-section-image">
                                                                <p className="ido-text-11 mb-1">TOKEN Name</p>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <p className="ido-text-12 mb-1">{active.tokenname}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row gap-3 px-2 mt-4">
                                                            <div className="steps-section-image">
                                                                <p className="ido-text-11 mb-1">TOKEN SUPPLY</p>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <p className="ido-text-12 mb-1">{active.tokensupply}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                            <div className="steps-section-image">
                                                                <p className="ido-text-11 mb-1">INITIAL SUPPLY</p>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <p className="ido-text-12 mb-1">{active.initialsupply}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                            <div className="steps-section-image">
                                                                <p className="ido-text-11 mb-1">TOKEN PRICE</p>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <p className="ido-text-12 mb-1">{active.tokenprice}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row gap-3 px-2 mt-2">
                                                            <div className="steps-section-image">
                                                                <p className="ido-text-11 mb-1">HARDCAP</p>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <p className="ido-text-12 mb-1">{active.hardcapvalue}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="d-flex flex-row gap-3 px-2 mt-2 mb-3">
                                                            <div className="steps-section-image">
                                                                <p className="ido-text-11 mb-1">SOCIAL</p>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <p className="ido-text-12 mb-1 d-flex">
                                                                    <AiOutlineTwitter className="ido-text-13" />
                                                                    <FaDiscord className="ido-text-13" />
                                                                    <AiOutlineInstagram className="ido-text-13" />
                                                                    <AiFillFacebook className="ido-text-13" />
                                                                    <FaTelegramPlane className="ido-text-13" />
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                </Link>
                                        </div>

                                        {/* <a href={"/blog/"+blogItems.blog_link} target="_blank">
                                                <div class="card h-100">
                                                    <img src={blogItems.blogimg} alt="" />
                                                    <div class="card-body">
                                                        <div class="banner-content">
                                                            <a><BsArrowUpRightCircle /></a>
                                                        </div>
                                                        <div className="d-flex flex-row">
                                                            <div>
                                                                <span className="blog-text-1">{blogItems.date}</span>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <span className="blog-text-2">{blogItems.blog_category}</span>
                                                            </div>
                                                        </div>
                                                        <p class="card-text">{blogItems.blog_title}</p>
                                                    </div>
                                                </div>
                                            </a> */}
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>

                </div>
            </div>
        </div>
    );
}
}
export default Idolist