import * as React from 'react';
import { useReactCountdown } from 'use-react-countdown';


export default function Buggy() {
    // Date in format (Month DD, YYYY hh:mm:ss)
    const { days, hours, minutes, seconds } = useReactCountdown(
        'March 29, 2023 17:30:00'
    );

    return (
        <div>

            <svg className='banner-top-fixed-timer-rool-image d-none d-lg-none' xmlns="http://www.w3.org/2000/svg" width="369.39" height="362.823" viewBox="0 0 369.39 362.823">
                <defs>
                    <filter id="Path_49" x="181.291" y="21.361" width="113.492" height="70.583" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_58" x="0" y="166.099" width="122.706" height="122.706" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-2" />
                        <feFlood flood-color="#1b9ffe" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_51" x="246.685" y="67.301" width="122.705" height="122.706" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-3" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur-3" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_52" x="273.746" y="173.723" width="70.583" height="113.493" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-4" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur-4" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_53" x="174.393" y="240.117" width="122.706" height="122.705" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-5" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur-5" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_54" x="75" y="265.178" width="113.493" height="70.583" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-6" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur-6" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="_24d_23h_22m_2s" x="89.897" y="168.677" width="189" height="49" filterUnits="userSpaceOnUse">
                        <feOffset input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-7" />
                        <feFlood flood-color="#1b9ffe" />
                        <feComposite operator="in" in2="blur-7" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="launch_on" x="103.897" y="130.677" width="155" height="49" filterUnits="userSpaceOnUse">
                        <feOffset input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-8" />
                        <feFlood flood-color="#1b9ffe" />
                        <feComposite operator="in" in2="blur-8" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(24 17)" fill="#000210" stroke="#707070" stroke-width="1">
                    <ellipse cx="159.5" cy="159" rx="159.5" ry="159" stroke="none" />
                    <ellipse cx="159.5" cy="159" rx="159" ry="158.5" fill="none" />
                </g>
                <g id="Group_7" data-name="Group 7" transform="translate(-3690 841)">
                    <g transform="matrix(1, 0, 0, 1, 3690, -841)" filter="url(#Path_49)">
                        <path id="Path_49-2" data-name="Path 49" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(-2784.1 -336.32)" fill="#1b9ffe" />
                    </g>
                    <path id="Path_56" data-name="Path 56" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(1412.329 1070.452) rotate(-45)" fill="#535353" opacity="0.37" />
                    <path id="Path_57" data-name="Path 57" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(3362.376 2303.492) rotate(-90)" fill="#1b9ffe" opacity="0.37" />
                    <g transform="matrix(1, 0, 0, 1, 3690, -841)" filter="url(#Path_58)">
                        <path id="Path_58-2" data-name="Path 58" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(1920.45 2636.47) rotate(-135)" fill="#1b9ffe" opacity="0.79" />
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 3690, -841)" filter="url(#Path_51)">
                        <path id="Path_51-2" data-name="Path 51" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(-1553.06 -2285.37) rotate(45)" fill="#1b9ffe" />
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 3690, -841)" filter="url(#Path_52)">
                        <path id="Path_52-2" data-name="Path 52" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(698.01 -2791.67) rotate(90)" fill="#1b9ffe" />
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 3690, -841)" filter="url(#Path_53)">
                        <path id="Path_53-2" data-name="Path 53" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(2645.77 -1559.63) rotate(135)" fill="#1b9ffe" />
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 3690, -841)" filter="url(#Path_54)">
                        <path id="Path_54-2" data-name="Path 54" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(3149.89 689.45) rotate(180)" fill="#1b9ffe" />
                    </g>
                    <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(3764.897 -773.323)" fill="none" stroke="#707070" stroke-width="1">
                        <circle cx="109" cy="109" r="109" stroke="none" />
                        <circle cx="109" cy="109" r="108.5" fill="none" />
                    </g>
                    <a href="https://youtu.be/VGe-w99l6MU" target="_blank"> <g transform="matrix(1, 0, 0, 1, 3690, -841)" filter="url(#_24d_23h_22m_2s)">
                        <text id="_24d_23h_22m_2s-2" data-name="24d 23h 22m 2s" transform="translate(136.9 202.68)" fill="#fff" font-size="23" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">For Demo</tspan></text>
                    </g>
                    </a>
                    <a href="https://youtu.be/VGe-w99l6MU" target="_blank"> <g transform="matrix(1, 0, 0, 1, 3690, -841)" filter="url(#launch_on)">
                        <text id="launch_on-2" data-name="launch on" transform="translate(118.9 164.68)" fill="#fff" font-size="23" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">Click Here</tspan></text>
                    </g>
                    </a>
                </g>
            </svg>



            <svg className='banner-top-fixed-timer-rool-image d-none d-lg-none' xmlns="http://www.w3.org/2000/svg" width="369.39" height="362.823" viewBox="0 0 369.39 362.823">
                <defs>
                    <filter id="Path_49" x="181.291" y="21.361" width="113.493" height="70.583" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_58" x="0" y="166.099" width="122.706" height="122.706" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-2" />
                        <feFlood flood-color="#1b9ffe" />
                        <feComposite operator="in" in2="blur-2" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_51" x="246.685" y="67.3" width="122.706" height="122.706" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-3" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur-3" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_52" x="273.745" y="173.723" width="70.583" height="113.493" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-4" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur-4" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_53" x="174.393" y="240.117" width="122.705" height="122.705" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-5" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur-5" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="Path_54" x="75" y="265.178" width="113.493" height="70.583" filterUnits="userSpaceOnUse">
                        <feOffset dx="2" dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-6" />
                        <feFlood flood-color="#00b9ff" flood-opacity="0.91" />
                        <feComposite operator="in" in2="blur-6" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="_24d_23h_22m_2s" x="89.898" y="168.677" width="189" height="49" filterUnits="userSpaceOnUse">
                        <feOffset input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-7" />
                        <feFlood flood-color="#1b9ffe" />
                        <feComposite operator="in" in2="blur-7" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                    <filter id="launch_on" x="103.898" y="130.677" width="155" height="49" filterUnits="userSpaceOnUse">
                        <feOffset input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="3" result="blur-8" />
                        <feFlood flood-color="#1b9ffe" />
                        <feComposite operator="in" in2="blur-8" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_49)">
                    <path id="Path_49-2" data-name="Path 49" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(-2784.1 -336.32)" fill="#1b9ffe" />
                </g>
                <path id="Path_56" data-name="Path 56" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(-2277.671 1911.452) rotate(-45)" fill="#535353" opacity="0.37" />
                <path id="Path_57" data-name="Path 57" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(-327.624 3144.492) rotate(-90)" fill="#1b9ffe" opacity="0.37" />
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_58)">
                    <path id="Path_58-2" data-name="Path 58" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(1920.45 2636.47) rotate(-135)" fill="#1b9ffe" opacity="0.79" />
                </g>
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_51)">
                    <path id="Path_51-2" data-name="Path 51" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(-1553.06 -2285.37) rotate(45)" fill="#1b9ffe" />
                </g>
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_52)">
                    <path id="Path_52-2" data-name="Path 52" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(698.01 -2791.67) rotate(90)" fill="#1b9ffe" />
                </g>
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_53)">
                    <path id="Path_53-2" data-name="Path 53" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(2645.77 -1559.63) rotate(135)" fill="#1b9ffe" />
                </g>
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_54)">
                    <path id="Path_54-2" data-name="Path 54" d="M3067.886,404.465c-36.695-38.459-95.493-39.78-95.493-39.78v18.241s47.593-.118,83.111,34.342" transform="translate(3149.89 689.45) rotate(180)" fill="#1b9ffe" />
                </g>
                <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(74.898 67.677)" fill="none" stroke="#707070" stroke-width="1">
                    <circle cx="109" cy="109" r="109" stroke="none" />
                    <circle cx="109" cy="109" r="108.5" fill="none" />
                </g>
                <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(25.898 16.677)" fill="none" stroke="#707070" stroke-width="1">
                    <ellipse cx="159.5" cy="159" rx="159.5" ry="159" stroke="none" />
                    <ellipse cx="159.5" cy="159" rx="159" ry="158.5" fill="none" />
                </g>
               <a href="https://youtu.be/VGe-w99l6MU" target="_blank"> <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#_24d_23h_22m_2s)">
                    <text id="_24d_23h_22m_2s-2" data-name="24d 23h 22m 2s" transform="translate(136.9 202.68)" fill="#fff" font-size="23" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">For Demo</tspan></text>
                </g>
                </a>
                <a href="https://youtu.be/VGe-w99l6MU" target="_blank"> <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#launch_on)">
                    <text id="launch_on-2" data-name="launch on" transform="translate(128.9 164.68)" fill="#fff" font-size="24" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">Click Here</tspan></text>
                </g>
                </a>
            </svg>

        </div>
    );
}