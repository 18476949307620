
import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import Navbar from "./separate/Navbar";
import Footer from "./separate/Footer";
import axios from "axios";
import loader from "../images/loader.gif";



export default function App() {
    const [post, setPost] = React.useState([]);

    const customStyles = {

        headCells: {
            style: {
                background: '#0E1434',
            },
        },

    };


    createTheme('solarized', {
        text: {
            primary: '#FFF',
            secondary: '#FFF',
        },
        background: {
            default: '#04051B',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },

        divider: {
            default: 'rgb(111 111 111 / 16%)',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const columns = [
        {
            name: "WalletID",
            selector: (row) => row.WalletID,
            sortable: true,
        },
        {
            name: "Referral Link",
            selector: (row) => row.ReferralLink,
            sortable: true
        },
        {
            name: "DCX Earned",
            selector: (row) => row.DCXEarned,
            sortable: true
        },
        {
            name: "Rank",
            selector: (row) => row.Rank,
            sortable: true
        },
        {
            name: "Level",
            selector: (row) => row.Level,
            sortable: true
        },


    ];

    const playerSummary = [
        {
            WalletID: <img className="leadership-level" src={loader} alt="loader" />,
            ReferralLink: <img className="leadership-level" src={loader} alt="loader" />,
            DCXEarned: <img className="leadership-level" src={loader} alt="loader" />,
            Rank: <img className="leadership-level" src={loader} alt="loader" />,
            Level:<img className="leadership-level" src={loader} alt="loader" />,
        },
        {
            WalletID: <img className="leadership-level" src={loader} alt="loader" />,
            ReferralLink: <img className="leadership-level" src={loader} alt="loader" />,
            DCXEarned: <img className="leadership-level" src={loader} alt="loader" />,
            Rank: <img className="leadership-level" src={loader} alt="loader" />,
            Level:<img className="leadership-level" src={loader} alt="loader" />,
        },
        {
            WalletID: <img className="leadership-level" src={loader} alt="loader" />,
            ReferralLink: <img className="leadership-level" src={loader} alt="loader" />,
            DCXEarned: <img className="leadership-level" src={loader} alt="loader" />,
            Rank: <img className="leadership-level" src={loader} alt="loader" />,
            Level:<img className="leadership-level" src={loader} alt="loader" />,
        }
        
    ];


    React.useEffect(() => {
        const baseURL = "https://www.clarisco.com/blogs/data";
        axios.get(baseURL).then((response) => {
            console.log(response, "res");
            setPost(response.data);

        });
    }, []);

    const handleRowClicked = (row) => {
        console.log(row.WalletID);
    };
    return (
        <div className="App">
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col py-5">

                        <DataTable
                            columns={columns}
                            data={playerSummary}
                            defaultSortFieldId
                            pagination={5}
                            onRowClicked={handleRowClicked}
                            highlightOnHover
                            theme="solarized"
                            customStyles={customStyles}
                            noDataComponent={<div>< img className="loader-gif" src={loader} alt="loader" /></div>}
                        />
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    );
}
