import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../images/d-ido.png";
import { IoHome, IoBulbOutline } from 'react-icons/io5';
import { BsLightningChargeFill } from 'react-icons/bs';
import { CiMap } from 'react-icons/ci';
import { FaDiscord, FaTelegramPlane } from 'react-icons/fa';
import { AiOutlineTeam, AiOutlineInstagram, AiOutlineTwitter, AiFillFacebook } from 'react-icons/ai';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper";

import banner from "../images/banner.png"
import Ethereum from "../images/ethereum.png"
import profile from "../images/profile.jpg"

import one from "../images/inner/1.png"
import two from "../images/inner/2.png"
import three from "../images/inner/3.png"

import Star2 from "../images/inner/star.png"
import Star1 from "../images/inner/star1.png"
import separator from "../images/inner/separator.png"
import headingleft from "../images/inner/headingleft.png"
import headingright from "../images/inner/headingright.png"

import character from "../images/team/character.png"
import team1 from "../images/team/team1.png"
import team2 from "../images/team/team2.png"
import team3 from "../images/team/team3.png"

import spaceman from "../images/spaceman.png"

import polkadot from "../images/partners/polkadot.png"


import bannertop1 from "../images/banner/banner-top-1.png"
import bannertop from "../images/banner/banner-top.png"
import bannertopparticles from "../images/banner/top-banner-particles.png"

import facebook from "../images/social/facebook.png"
import github from "../images/social/github.png"
import linkedin from "../images/social/linkedin.png"
import twitter from "../images/social/twitter.png"
import { HashLink } from 'react-router-hash-link';
import Axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Navbar from "../Pages/separate/Navbar";
import Footer from "../Pages/separate/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MySwal = withReactContent(Swal)

function Home() {
    const navigate = useNavigate();
    const [inputValues, setInputValue] = useState({
        name: "",
        email: "",
        projectname: "",
        description: "",
        investors: "",
        smartaudit: "",
        whitepaperlink: "",
        websitelink: "",
        githublink: "",
        telegramgroup: "",
        telegramlink: ""
    });

    const [anonValues, setanonValues] = useState("")
    const maxDescriptionCharacters = 300;
    const [validation, setValidation] = useState({
        name: "",
        email: "",
        projectname: "",
        description: "",
        investors: "",
        smartaudit: "",
        whitepaperlink: "",
        websitelink: "",
        anonValues: "",
        githublink: "",
        telegramgroup: "",
        telegramlink: "",
        allerror: "1"
    });

    function handleChange(event) {
        const { name, value } = event.target;

        if (name === 'description' && value.length > maxDescriptionCharacters) {
            // If the description exceeds the character limit, prevent further input
            return;
        }

        checkValidation();
        setInputValue({ ...inputValues, [name]: value });
    }

    function teamselect(event) {
        checkValidation()
        setanonValues(event)
    }

    function isValidUrl(url) {
        const urlRegex = /^(https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
        return urlRegex.test(url);
    }

    const checkValidation = () => {
        let errors = validation;

        // Validate the "Name" field
        if (!inputValues.name.trim()) {
            errors.allerror = "1";
            errors.name = "Name is required";
        } else {
            errors.name = "";
            errors.allerror = "0";
        }

        // Validate the "Email" field
        const emailCond = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
        if (!inputValues.email.trim()) {
            errors.allerror = "1";
            errors.email = "Email is required";
        } else if (!inputValues.email.match(emailCond)) {
            errors.allerror = "1";
            errors.email = "Please enter a valid email address";
        } else {
            errors.email = "";
            errors.allerror = "0";
        }


        // Validate the "Project Name" field
        if (!inputValues.projectname.trim()) {
            errors.allerror = "1";
            errors.projectname = "Project Name is required";
        } else {
            errors.projectname = "";
            errors.allerror = "0";
        }

        // Validate the "Description" field
        if (!inputValues.description.trim()) {
            errors.allerror = "1";
            errors.description = "Description is required";
        } else {
            errors.description = "";
            errors.allerror = "0";
        }

        // Validate the "Is your team Public or Anon?" field
        if (!anonValues.trim()) {
            errors.allerror = "1";
            errors.anonValues = "Value is required";
        } else {
            errors.anonValues = "";
            errors.allerror = "0";
        }

        // Validate the "Investors" field
        if (!inputValues.investors.trim()) {
            errors.allerror = "1";
            errors.investors = "Investors is required";
        }
        if (inputValues.investors.trim() === '') {
            errors.allerror = "1";
            errors.investors = "Investors cannot be empty";
        } else {
            errors.investors = "";
            errors.allerror = "0";
        }

        // Validate the "Smart Audit" field
        if (!inputValues.smartaudit.trim()) {
            errors.allerror = "1";
            errors.smartaudit = "Smart Audit is required";
        } else {
            errors.smartaudit = "";
            errors.allerror = "0";
        }

        // Validate the "Whitepaper Link"
        if (!isValidUrl(inputValues.whitepaperlink)) {
            errors.allerror = "1";
            errors.whitepaperlink = "Please enter a valid URL for the Whitepaper link";
        } else {
            errors.whitepaperlink = "";
        }

        // Validate the "Website Link" if it's provided
        if (inputValues.websitelink.trim() && !isValidUrl(inputValues.websitelink)) {
            errors.allerror = "1";
            errors.websitelink = "Please enter a valid URL for the Website link";
        } else {
            errors.websitelink = "";
        }

        // Validate the "GitHub Link"
        if (!isValidUrl(inputValues.githublink)) {
            errors.allerror = "1";
            errors.githublink = "Please enter a valid URL for the GitHub link";
        } else {
            errors.githublink = "";
        }

        // Validate the "Telegram Group Link"
        if (!isValidUrl(inputValues.telegramgroup)) {
            errors.allerror = "1";
            errors.telegramgroup = "Please enter a valid URL for the Telegram Group link";
        } else {
            errors.telegramgroup = "";
        }

        // Validate the "Telegram Link"
        if (!inputValues.telegramlink.trim()) {
            errors.allerror = "1";
            errors.telegramlink = "telegramlink is required";
        } else {
            errors.telegramlink = "";
            errors.allerror = "0";
        }

        setValidation(errors);

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(e,validation)

        // Validate the "Email" field
        // Check if the email contains ".com"
        if (!inputValues.email.includes(".com")) {
            setValidation((prevErrors) => ({
                ...prevErrors,
                allerror: "1",
                email: "Email must contain '.com'",
            }));
            toast("Email must contain '.com'");
            return;
        }

        // Continue with the rest of the email validation
        const emailCond = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
        if (!inputValues.email.trim()) {
            setValidation((prevErrors) => ({
                ...prevErrors,
                allerror: "1",
                email: "Email is required",
            }));
            toast("Email is required");
            return;
        } else if (!inputValues.email.match(emailCond)) {
            setValidation((prevErrors) => ({
                ...prevErrors,
                allerror: "1",
                email: "Please enter a valid email address",
            }));
            toast("Please enter a valid email address");
            return;
        } else {
            setValidation((prevErrors) => ({
                ...prevErrors,
                email: "",
                allerror: "0",
            }));
        }


        if (
            inputValues.name.trim() === "" ||
            inputValues.email.trim() === "" ||
            inputValues.projectname.trim() === "" || !inputValues.email.match(emailCond) ||
            inputValues.description.trim() === "" ||
            anonValues.trim() === "" ||
            inputValues.investors.trim() === "" ||
            inputValues.smartaudit.trim() === "" ||
            inputValues.whitepaperlink === "" ||
            inputValues.githublink === "" ||
            inputValues.telegramgroup === "" ||
            // !isValidUrl(inputValues.whitepaperlink) === "" ||
            // !isValidUrl(inputValues.githublink) === "" ||
            // !isValidUrl(inputValues.telegramgroup) === "" ||
            inputValues.telegramlink.trim() === ""
        ) {
            toast("Please fill all the required details!");
            return;
        } else if (validation.allerror === "0") {
            navigate('/idoformtoken', { state: { values: inputValues, anon: anonValues } });
        }
    };


    return (
        <div className="App">
            <Navbar />
            <ToastContainer />
            <div class="hero-section-ido-launchpad-banner py-5">
                <div class="container">
                    <div class="row g-4 justify-content-around">
                        <div class="col-lg-8 application-form-ido-section">
                            <p className="ido-text-6 mb-2">D-IDO Application Form<img className="star-second-image" src={Star2} alt="star" /></p>
                            <p className="ido-text-3 mb-5" style={{ textTransform: "capitalize" }}>
                                Welcome! :) Please apply to launch on D-IDO so we can begin to review your project application.
                            </p>
                            <form className="" onSubmit={handleSubmit}>
                                <div class="mb-4">
                                    <label for="name" class="form-label">Your Name</label>
                                    <input type="text" class="form-control" id="name" name='name' aria-describedby="emailHelp" onChange={(e) => handleChange(e)} value={inputValues.name} />
                                    {validation.name && <p className="errors" style={{ color: "red" }}>{validation.name}</p>}
                                </div>
                                <div class="mb-4">
                                    <label for="email" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="email" name='email' onChange={(e) => handleChange(e)} value={inputValues.email} />
                                    {validation.email && <p className="errors" style={{ color: "red" }}>{validation.email}</p>}
                                    <div id="emailHelp" class="form-text">example@example.com</div>
                                </div>
                                <div class="mb-4">
                                    <label for="projectname" class="form-label">Project Name</label>
                                    <input type="text" class="form-control" id="projectname" name="projectname" onChange={(e) => handleChange(e)} value={inputValues.projectname} />
                                    {validation.projectname && <p className="errors" style={{ color: "red" }}>{validation.projectname}</p>}
                                </div>
                                <div class="mb-4">
                                    <label for="exampleFormControlTextarea1" class="form-label">
                                        Tell us more about your project, the more info you give us the more likely we will consider your application.
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="description"
                                        rows="3"
                                        name="description"
                                        onChange={(e) => handleChange(e)}
                                        value={inputValues.description}
                                    ></textarea>
                                    {validation.description && (
                                        <p className="errors" style={{ color: 'red' }}>
                                            {validation.description}
                                        </p>
                                    )}
                                    <p className="char-limit">
                                        {inputValues.description.length}/{maxDescriptionCharacters}
                                    </p>
                                </div>

                                <div class="mb-4">
                                    <label for="exampleFormControlTextarea1" class="form-label">Is your team Public or Anon?</label>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="publicteam" name="publicteam" onClick={(e) => teamselect("public")} />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Public
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="anon" name="publicteam" onClick={(e) => teamselect("anon")} />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Anon
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" id="mixed" name="publicteam" onClick={(e) => teamselect("mixed")} />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Mixed
                                        </label>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label for="investors" class="form-label">Advisors/Backers/Investors</label>
                                    <input type="text" class="form-control" id="investors" name="investors" onChange={(e) => handleChange(e)} value={inputValues.investors} />
                                    {validation.investors && <p className="errors" style={{ color: "red" }}>{validation.investors}</p>}
                                </div>
                                <div class="mb-4">
                                    <label class="form-label">Has your smart contract been audited?</label>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="smartaudit-yes"
                                            name="smartaudit"
                                            value="Yes"
                                            onChange={(e) => handleChange(e)}
                                            checked={inputValues.smartaudit === "Yes"}
                                        />
                                        <label class="form-check-label" for="smartaudit-yes">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="smartaudit-no"
                                            name="smartaudit"
                                            value="No"
                                            onChange={(e) => handleChange(e)}
                                            checked={inputValues.smartaudit === "No"}
                                        />
                                        <label class="form-check-label" for="smartaudit-no">
                                            No
                                        </label>
                                    </div>
                                    {validation.smartaudit && (
                                        <p className="errors" style={{ color: "red" }}>
                                            {validation.smartaudit}
                                        </p>
                                    )}
                                </div>
                                <div class="mb-4">
                                    <label for="whitepaperlink" class="form-label">Link to Whitepaper or Lite paper?</label>
                                    <input type="text" class="form-control" id="whitepaperlink" name="whitepaperlink" onChange={(e) => handleChange(e)} value={inputValues.whitepaperlink} />
                                    {validation.whitepaperlink && <p className="errors" style={{ color: "red" }}>{validation.whitepaperlink}</p>}
                                </div>
                                <div class="mb-4">
                                    <label for="websitelink" class="form-label">Website Link (if any)?</label>
                                    <input type="text" class="form-control" id="websitelink" name="websitelink" onChange={(e) => handleChange(e)} value={inputValues.websitelink} />
                                    {/* {validation.websitelink && <p className="errors" style={{color:"red"}}>{validation.websitelink}</p>}  */}
                                </div>
                                <div class="mb-4">
                                    <label for="githublink" class="form-label">GitHub Link?</label>
                                    <input type="text" class="form-control" id="githublink" name="githublink" onChange={(e) => handleChange(e)} value={inputValues.githublink} />
                                    {validation.githublink && <p className="errors" style={{ color: "red" }}>{validation.githublink}</p>}
                                </div>
                                <div class="mb-4">
                                    <label for="telegramgroup" class="form-label">Telegram Group Link?</label>
                                    <input type="text" class="form-control" id="telegramgroup" name="telegramgroup" onChange={(e) => handleChange(e)} value={inputValues.telegramgroup} />
                                    {validation.telegramgroup && <p className="errors" style={{ color: "red" }}>{validation.telegramgroup}</p>}
                                </div>
                                <div class="mb-4">
                                    <label for="telegramlink" class="form-label">Your Telegram handle (@username)</label>
                                    <input type="text" class="form-control" id="telegramlink" name="telegramlink" onChange={(e) => handleChange(e)} value={inputValues.telegramlink} />
                                    {validation.telegramlink && <p className="errors" style={{ color: "red" }}>{validation.telegramlink}</p>}
                                </div>
                                <div className="text-center">
                                    <button type="submit" class="ido-launchpad-button">NEXT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Home;
