import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    Link
} from "react-router-dom";
import logo from "../images/d-ido.png";
import { IoHome, IoBulbOutline } from 'react-icons/io5';
import { BsLightningChargeFill } from 'react-icons/bs';
import { CiMap, CiLock } from 'react-icons/ci';
import { CgLoadbarDoc } from 'react-icons/cg';
import { FaTelegramPlane } from 'react-icons/fa';
import { AiOutlineTeam, AiOutlineTwitter } from 'react-icons/ai';
import { TfiWorld } from 'react-icons/tfi';
import { BsClockFill } from 'react-icons/bs';
import { MdOutlineContentCopy } from 'react-icons/md';

import profile from "../images/profile.jpg"
import Bnb from "../images/coin/bnb.png"

import headingleft from "../images/inner/headingleft.png"
import headingright from "../images/inner/headingright.png"

import facebook from "../images/social/facebook.png"
import github from "../images/social/github.png"
import linkedin from "../images/social/linkedin.png"
import twitter from "../images/social/twitter.png"
import { HashLink } from 'react-router-hash-link';
import { idoAbi } from './ido'
import Web3 from 'web3';
import $ from "jquery";
import Navbar from "./separate/Navbar";
import Footer from "./separate/Footer";
import Axios from 'axios';
import { API_BACKEND_URL } from '../config/config';
import tokenContractAbi from '../ABI/tokenContractAbi.json';
import { ADMIN_WALLET_ADDRESS } from '../config/config';
import { BSC_NETWORK_NAME } from '../config/config';
import { MATIC_NETWORK_NAME } from '../config/config';
import { DCHAIN_NETWORK_NAME } from '../config/config';
import { BSC_CHAIN_ID } from '../config/config';
import { MATIC_CHAIN_ID } from '../config/config';
import { D_LAUNCH_PAD_CONTRACT_ADDRESS } from '../config/config';
import { USDT_TOKEN_ADDRESS } from '../config/config';
import { BUSD_TOKEN_ADDRESS } from '../config/config';
import ido_Contract_Abi from '../ABI/Ido_contract_abi.json';
import { DCHAIN_CHAIN_ID } from '../config/config'
import Busd_Usdt_Token_Abi from '../ABI/busd_usdt_token_abi.json';
import Swal from 'sweetalert2';

import axios from "axios";

function Completedidodetails() {
    const { state } = useLocation();
    const { from } = state;
    const [name, setname] = useState("")
    const [tokensupply, settokensupply] = useState("")
    const [initialsupply, setinitialsupply] = useState("")
    const [startdate, setstartdate] = useState("")
    const [enddate, setenddate] = useState("")
    const [tokenprice, settokenprice] = useState("0")
    const [hardcapvalue, sethardcapvalue] = useState("")
    const [blockchainvalues, setblockchainvalues] = useState("")
    const [description, setdescription] = useState("")
    const [image, setimage] = useState("")
    const [fromvalue, setfromvalue] = useState("0")


    const [ProjectsDetails, setProjectsDetails] = useState([]);
    const [TokenContractAddress, setTokenContractAddress] = useState();
    const [Tokensymbol, setTokensymbol] = useState();
    const [SelectedTokenNetwork, setSelectedTokenNetwork] = useState();
    const [TokenPrice, setTokenPrice] = useState();
    const [TokenName, setTokenName] = useState();
    const [TokenDescription, setTokenDescription] = useState();
    const [DeployedContractAddresses, setDeployedContractAddresses] = useState();
    // useEffect(() => {
    //     const pathsplit = window.location.pathname.split('/')[3]
    //     const tokenvalues = from;
    //     for (var i = 0; i < tokenvalues.length; i++) {
    //         if (tokenvalues[i].name == pathsplit) {
    //             setname(tokenvalues[i].name)
    //             setdescription(tokenvalues[i].description)
    //             settokensupply(tokenvalues[i].tokensupply)
    //             setinitialsupply(tokenvalues[i].initialsupply)
    //             setstartdate(tokenvalues[i].startdate)
    //             setenddate(tokenvalues[i].enddate)
    //             sethardcapvalue(tokenvalues[i].hardcapvalue)
    //             setblockchainvalues(tokenvalues[i].blockchainvalues)
    //             // setimage(tokenvalues[i].image)
    //         }
    //     }
    // });

    // console.log('window.ethereum.chainId---', window.ethereum.chainId);
    async function handleChange(event) {
        try {


            setfromvalue(event.target.value)
            if (SelectedTokenNetwork == BSC_NETWORK_NAME) {
                const chainId = BSC_CHAIN_ID;
                var web3js = new Web3(window.ethereum);
                const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
                if (currentChainId !== chainId) {
                    try {
                        const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: web3js.utils.toHex(chainId) }] });
                        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
                        const idocontract = new web3js.eth.Contract(idoAbi, TokenContractAddress);
                        const amountwei = await web3js.utils.toWei(event.target.value, 'ether')
                        const tokenbal = await idocontract.methods.gettoken(amountwei).call()
                        const ercbal = await web3js.utils.fromWei(tokenbal, 'ether')
                        settokenprice(ercbal)

                    } catch (switchError) {
                        if (switchError.code === 4902) {
                        }
                    }
                }
            } else if (SelectedTokenNetwork == MATIC_NETWORK_NAME) {
                const chainId = MATIC_CHAIN_ID;
                var web3js = new Web3(window.ethereum);
                const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
                if (currentChainId !== chainId) {
                    try {
                        const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: web3js.utils.toHex(chainId) }] });
                        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
                        const idocontract = new web3js.eth.Contract(idoAbi, TokenContractAddress);
                        const amountwei = await web3js.utils.toWei(event.target.value, 'ether')
                        const tokenbal = await idocontract.methods.gettoken(amountwei).call()
                        const ercbal = await web3js.utils.fromWei(tokenbal, 'ether')
                        settokenprice(ercbal)

                    } catch (switchError) {
                        if (switchError.code === 4902) {
                        }
                    }
                }
            } else if (SelectedTokenNetwork == DCHAIN_NETWORK_NAME) {
                const chainId = DCHAIN_CHAIN_ID;
                var web3js = new Web3(window.ethereum);
                const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
                if (currentChainId !== chainId) {
                    try {
                        const currentChainId = await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: web3js.utils.toHex(chainId) }] });
                        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
                        const idocontract = new web3js.eth.Contract(idoAbi, TokenContractAddress);
                        const amountwei = await web3js.utils.toWei(event.target.value, 'ether')
                        const tokenbal = await idocontract.methods.gettoken(amountwei).call()
                        const ercbal = await web3js.utils.fromWei(tokenbal, 'ether')
                        settokenprice(ercbal)

                    } catch (switchError) {
                        if (switchError.code === 4902) {
                        }
                    }
                } else {
                    console.log("fyugh")
                }
            }
        } catch (error) {

        }
    }

    const max = async () => {
        try {
            var web3js = new Web3(window.ethereum);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const getbal = await web3js.eth.getBalance(account[0]);
            const weival = web3js.utils.fromWei(getbal, 'ether')
            setfromvalue(weival)
        } catch (error) {

        }
    }

    // const idosubmit = async () => {
    //     try {
    //         const expectedChainId = getExpectedChainId();

    //         if (expectedChainId) {
    //             await switchToExpectedChain(expectedChainId);

    //             const web3js = new Web3(window.ethereum);
    //             const account = await requestAccount();

    //             const launchPad_Contract = getLaunchPadContract(web3js);
    //             const usdt_Token_Contract = getUsdtTokenContract(web3js);

    //             const fromAccount = account[0];
    //             const amountInWei = web3js.utils.toWei(fromvalue, 'ether');

    //             console.log('TokenContractAddress---', TokenContractAddress);
    //             const approvalStatus = await approveTokenTransfer(usdt_Token_Contract, amountInWei, fromAccount);
    //             console.log('tokeApproveFunction---', approvalStatus);

    //             if (approvalStatus) {
    //                 const gasLimit = 2000000;
    //                 const gasPrice = web3js.utils.toWei('10', 'gwei');
    //                 const tokenName = await launchPad_Contract.methods.depositToken().call();
    //                 const transaction = await transferIdoToken(launchPad_Contract, fromAccount, amountInWei, gasLimit, gasPrice);
    //                 console.log('transaction----', transaction);

    //                 const tokenNeedToSend = fromvalue * TokenPrice;
    //                 const userDepositedTransaction = await getUserDepositedTransactionDetails(transaction, tokenNeedToSend);
    //                 // console.log('userDepositedTransaction---', userDepositedTransaction);
    //             }
    //         } else {
    //             alert('Invalid network selection');
    //         }
    //     } catch (error) {
    //         // Handle errors here
    //     }
    // };

    // const getExpectedChainId = () => {
    //     switch (SelectedTokenNetwork) {
    //         case BSC_NETWORK_NAME:
    //             return BSC_CHAIN_ID;
    //         case MATIC_NETWORK_NAME:
    //             return MATIC_CHAIN_ID;
    //         case DCHAIN_NETWORK_NAME:
    //             return DCHAIN_CHAIN_ID;
    //         default:
    //             return null;
    //     }
    // };

    // const switchToExpectedChain = async (expectedChainId) => {
    //     const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

    //     if (currentChainId !== expectedChainId) {
    //         await window.ethereum.request({
    //             method: 'wallet_switchEthereumChain',
    //             params: [{ chainId: expectedChainId }],
    //         });
    //     }
    // };

    // const requestAccount = async () => {
    //     return await window.ethereum.request({ method: 'eth_requestAccounts' });
    // };

    // const getLaunchPadContract = (web3js) => {
    //     return new web3js.eth.Contract(ido_Contract_Abi, D_LAUNCH_PAD_CONTRACT_ADDRESS);
    // };

    // const getUsdtTokenContract = (web3js) => {
    //     return new web3js.eth.Contract(Busd_Usdt_Token_Abi, TokenContractAddress);
    // };

    // const approveTokenTransfer = async (usdt_Token_Contract, amountInWei, fromAccount) => {
    //     try {
    //         const result = await usdt_Token_Contract.methods.approve(D_LAUNCH_PAD_CONTRACT_ADDRESS, amountInWei).send({
    //             from: fromAccount,
    //         });
    //         return result.status === true;
    //     } catch (error) {
    //         // Handle errors here
    //         return false;
    //     }
    // };

    // const transferIdoToken = async (launchPad_Contract, fromAccount, amountInWei, gasLimit, gasPrice) => {
    //     try {
    //         return await launchPad_Contract.methods.transferIdoToken(fromAccount, amountInWei, TokenContractAddress).send({
    //             from: fromAccount,
    //             gasLimit: gasLimit,
    //             gasPrice: gasPrice,
    //         });
    //     } catch (error) {
    //         // Handle errors here
    //         return null;
    //     }
    // };

    // const getUserDepositedTransactionDetails = async (transaction, tokenNeedToSend) => {
    //     try {
    //         return await axios.post(`${API_BACKEND_URL}getTheUserDepositedTransactionDetails`, {
    //             transaction: transaction,
    //             tokenNeedToSend: tokenNeedToSend,
    //             TokenContractAddress: D_LAUNCH_PAD_CONTRACT_ADDRESS,
    //             SelectedTokenNetwork: SelectedTokenNetwork,
    //         });
    //     } catch (error) {
    //         // Handle errors here
    //         return null;
    //     }
    // };


    const idosubmit = async () => {
        try {
            const location = window.location.pathname.split('/')[2]

            // console.log('---SelectedTokenNetwork', SelectedTokenNetwork);
            const expectedChainId = SelectedTokenNetwork === BSC_NETWORK_NAME ? BSC_CHAIN_ID : SelectedTokenNetwork === MATIC_NETWORK_NAME ? MATIC_CHAIN_ID : SelectedTokenNetwork === DCHAIN_NETWORK_NAME ? DCHAIN_CHAIN_ID : null;

            if (expectedChainId) {
                const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

                if (currentChainId !== expectedChainId) {
                    // Switch to the expected chain
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: expectedChainId }],
                    });


                } else {
                    const web3js = new Web3(window.ethereum);
                    const account = await window.ethereum.request({ method: 'eth_requestAccounts' });

                    const launchPad_Contract = new web3js.eth.Contract(ido_Contract_Abi, DeployedContractAddresses);
                    const tokenName = await launchPad_Contract.methods.depositToken().call();
                    // console.log('tokenName---', tokenName);
                    const usdt_Token_Contract = new web3js.eth.Contract(Busd_Usdt_Token_Abi, tokenName);

                    const fromAccount = (await web3js.eth.getAccounts())[0];
                    const amountInWei = web3js.utils.toWei(fromvalue, 'ether');
                    const gasLimit = 2000000
                    const gasPrice = web3js.utils.toWei('10', 'gwei');
                    console.log('DeployedContractAddresses---', DeployedContractAddresses);
                    const tokeApproveFunction = await usdt_Token_Contract.methods.approve(DeployedContractAddresses, amountInWei).send({
                        from: fromAccount
                    }).on('transactionHash', (hash) => {
                        console.log(hash);
                        if (hash) {

                            Swal.fire({
                                title: "Transaction in Progress",
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading();
                                    Swal.disableButtons();

                                }
                            });

                        }
                    }).on('receipt', async (receipt) => {

                        console.log('receipt----', receipt);
                        // Perform the token transfer
                        const transaction = await launchPad_Contract.methods.transferIdoToken(fromAccount, amountInWei, TokenContractAddress).send({
                            from: fromAccount,
                            to: DeployedContractAddresses,
                            gasLimit: gasLimit,
                            gasPrice: gasPrice
                        }).on('transactionHash', (hash) => {
                            console.log('transactionHash: ', hash);
                            if (hash) {

                                Swal.fire({
                                    title: "Transaction in Progress",
                                    allowOutsideClick: false,
                                    didOpen: () => {
                                        Swal.showLoading();
                                        Swal.disableButtons();

                                    }
                                });

                            }
                        }).on('receipt', async (receipt) => {
                            console.log('transaction----', receipt);


                            if (receipt.status == true) {
                                Swal.fire({
                                    title: "Success...!",
                                    html: "Transaction Success",
                                    icon: 'success'
                                }).then((result) => {

                                    console.log(result);
                                    if (result.isConfirmed) {
                                        window.location.href = '/activeido' + '/' + location;
                                    }
                                });
                            }

                        }).on('error', (err, error) => {
                            if (error) {

                            }
                            if (err.code == 4001) {
                                Swal.fire('Transaction is Cancelled')
                            }

                        })

                    })
                    // return false;
                    // if (tokeApproveFunction.status == true) {
                    //     const gasLimit = 2000000
                    //     const gasPrice = web3js.utils.toWei('10', 'gwei');

                    //     // Perform the token transfer
                    //     const transaction = await launchPad_Contract.methods.transferIdoToken(fromAccount, amountInWei, TokenContractAddress).send({
                    //         from: fromAccount,
                    //         to: DeployedContractAddresses,
                    //         gasLimit: gasLimit,
                    //         gasPrice: gasPrice
                    //     });
                    //     console.log('transaction----', transaction);
                    //     const tokenNeedToSend = fromvalue * TokenPrice;
                    //     const userDepositedTransaction = await axios.post(`${API_BACKEND_URL}getTheUserDepositedTransactionDetails`, {
                    //         transaction: transaction, // Use transactionHash to send to the backend
                    //         tokenNeedToSend: tokenNeedToSend,
                    //         TokenContractAddress: DeployedContractAddresses,
                    //         SelectedTokenNetwork: SelectedTokenNetwork
                    //     });
                    // }
                    // loader.style.display = 'none';
                    // console.log('userDepositedTransaction---', userDepositedTransaction);
                }
            } else {
                alert('Invalid network selection');
            }
        } catch (error) {
            // Handle errors here
        }
    };


    const location = useLocation();

    const formatDateTime = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const getProjectsDetailsFromDB = async () => {
        const location = window.location.pathname.split('/')[2]
        // console.log('location---', location);

        const activeProjects = [];

        const getdetails = await Axios.post(`${API_BACKEND_URL}projectId`, { DBID: location });
        const launchPad = getdetails.data.idoapp;

        const endDate = new Date(launchPad.enddate);
        const startDate = new Date(launchPad.startdate);
        const formattedStartDateTime = formatDateTime(startDate);
        const formattedEndDateTime = formatDateTime(endDate);

        const project = {
            _id: launchPad._id,
            name: launchPad.name,
            description: launchPad.description,
            tokensupply: launchPad.tokensupply,
            initialsupply: launchPad.initialsupply,
            tokenprice: launchPad.tokenprice,
            hardcapvalue: launchPad.hardcapvalue,
            projectname: launchPad.projectname,
            blockchainvalues: launchPad.blockchainvalues,
            startdate: formattedStartDateTime,
            enddate: formattedEndDateTime,
            // image: BSC,
            tokenname: launchPad.tokenname,
            tokensymbol: launchPad.tokensymbol,
            tokendecimal: launchPad.tokendecimal,
            contractaddress: launchPad.contractaddress,
            image: launchPad.image,
            deployedContractAddresses: launchPad.deployedContractAddresses,
            whitepaper: launchPad.whitepaperlink,
            twitter: launchPad.telegramlink,
            website: launchPad.websitelink,
            telegram: launchPad.telegramgroup
        };
        activeProjects.push(project);
        // console.log('project---', project);
        setProjectsDetails(activeProjects);

        setTokenContractAddress(launchPad.contractaddress);
        setTokensymbol(launchPad.tokensymbol);
        setSelectedTokenNetwork(launchPad.blockchainvalues);
        setTokenPrice(launchPad.tokenprice);
        setTokenName(launchPad.tokenname);
        setTokenDescription(launchPad.description)
        setimage(launchPad.image);
        setDeployedContractAddresses(launchPad.deployedContractAddresses)
    };

    const redirection=(()=>{
        window.location.href="https://buyidolauchpad.d-ecosystem.io/";
    })

    useEffect(() => {
        getProjectsDetailsFromDB();
    }, [])

    return (
        <div className="App">
            <Navbar />


            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="d-flex flex-row gap-3 mb-3 align-items-center">
                            <div className="ido-active-image-1"><img src={profile} className="" alt="logo" /></div>
                            <div className="">
                                <p className="ido-active-text-1 mb-0">{TokenName}</p>
                            </div>
                            <div className="ms-auto ido-active-image-2"><img src='https://s2.coinmarketcap.com/static/img/coins/64x64/25799.png' className="" alt="logo" /></div>
                            <div className="">
                                <button type="button" class="ido-active-button-1">ACTIVE</button>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="">
                                <p className="ido-active-text-2">{TokenDescription}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-row gap-4">
                            <div className="">
                                <a className="" href="http://website.com"><TfiWorld className="ido-icon-button-1" /> Website</a>
                            </div>
                            <div className="">
                                <a className="" href="http://twitter.com"><AiOutlineTwitter className="ido-icon-button-1" /> Twitter</a>
                            </div>
                            <div className="">
                                <a className="" href="http://telegram.com"><FaTelegramPlane className="ido-icon-button-1" /> Telegram</a>
                            </div>
                            <div className="">
                                <a className="" href="http://document.com"><CgLoadbarDoc className="ido-icon-button-1" /> Document</a>
                            </div>
                        </div>
                        <div className="mt-5 active-ido-background">
                            <p className="ido-active-text-3 mb-0">IDO Details</p>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <p className="ido-active-text-4">Registration Start</p>
                                    <p className="ido-active-text-4">Registration End</p>
                                    {/* <p className="ido-active-text-4">Levels Round Open</p>
                                    <p className="ido-active-text-4">Levels Round End</p>
                                    <p className="ido-active-text-4">FCFS Opens</p>
                                    <p className="ido-active-text-4">FCFS Closes</p> */}
                                    <p className="ido-active-text-4">Swap Rate</p>
                                    <p className="ido-active-text-4">Hard Cap</p>
                                    {/* <p className="ido-active-text-4">Total Whitelisted Users</p>
                                    <p className="ido-active-text-4">Total Users Participated</p>
                                    <p className="ido-active-text-4">Total Funds Swapped</p>
                                    <p className="ido-active-text-4">Access Type</p> */}
                                </div>
                                <div className="col-lg-6">
                                    {ProjectsDetails.map((rowData, index) => (
                                        <>
                                            <p className="ido-active-text-4">: {rowData.startdate}</p>
                                            <p className="ido-active-text-4">: {rowData.enddate}</p>
                                            {/* <p className="ido-active-text-4">: TBA</p>
                                            <p className="ido-active-text-4">: TBA</p>
                                            <p className="ido-active-text-4">: TBA</p>
                                            <p className="ido-active-text-4">: TBA</p> */}
                                            <p className="ido-active-text-4">: 1 {rowData.tokensymbol} = {rowData.tokenprice} USDT</p>
                                            <p className="ido-active-text-4">: {rowData.hardcapvalue}</p>
                                            {/* <p className="ido-active-text-4">: 0</p>
                                            <p className="ido-active-text-4">: 0</p>
                                            <p className="ido-active-text-4">: N/A</p>
                                            <p className="ido-active-text-4">: Levels</p> */}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 active-ido-background">
                            <p className="ido-active-text-3 mb-0">Token information</p>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <p className="ido-active-text-4">Token</p>
                                    <p className="ido-active-text-4">Network</p>
                                    {/* <p className="ido-active-text-4">Token Listing</p> */}
                                    <p className="ido-active-text-4">Contract Address (don't send money)</p>
                                    <p className="ido-active-text-4">Total Supply</p>
                                    <p className="ido-active-text-4">Initial Supply</p>
                                    {/* <p className="ido-active-text-4">Market Cap at Listing</p> */}
                                </div>
                                <div className="col-lg-6">
                                    {ProjectsDetails.map((rowData, index) => (
                                        <>
                                            <p className="ido-active-text-4">: {rowData.name}</p>
                                            <p className="ido-active-text-4">: {rowData.blockchainvalues}</p>
                                            {/* <p className="ido-active-text-4">: 26 May 2022</p> */}
                                            <p className="ido-active-text-4">: {rowData.contractaddress} <button className="ido-copy-button"><MdOutlineContentCopy className="me-2" /> copy</button></p>
                                            <p className="ido-active-text-4">: {rowData.tokensupply} {rowData.tokensymbol}</p>
                                            <p className="ido-active-text-4">: {rowData.initialsupply} {rowData.tokensymbol}</p>
                                            {/* <p className="ido-active-text-4">: 301,950 $</p> */}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 active-ido-background">
                            <p className="ido-active-text-3 mb-0">Distribution</p>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <p className="ido-active-text-4">Distribution</p>
                                    <p className="ido-active-text-4">Vesting</p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="ido-active-text-4">: By D-IDO</p>
                                    <p className="ido-active-text-4">: 5% unlock on TGE, 12 months vesting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="mt-4 active-ido-background">
                            <div className="d-flex flex-row gap-3 mb-3 align-items-center">
                                <div className="ido-active-image-3"><img src={profile} className="" alt="logo" /></div>
                                <div className="">
                                    <p className="ido-active-text-5 mb-0">{TokenName}</p>
                                    <p className="ido-active-text-6 mb-0">{SelectedTokenNetwork} / {Tokensymbol}</p>
                                </div>
                                <div className="ms-auto">
                                    <a className="ido-level-button-1 d-flex"><CiLock className="me-1 mt-1" />Level</a>
                                </div>
                            </div>
                            <div className="d-flex flex-row gap-3 mb-3 align-items-center mt-4 id-active-background-bg">
                                <div className=""><BsClockFill style={{ color: "#f4b844" }} className="me-2" /> IDO Open in</div>
                                <div className="ms-auto">{Tokensymbol}</div>
                            </div>
                            <div style={{ position: "relative" }}>
                                <div className="ido-active-buy-section" id="ido-buy-enable-disable">
                                    
                                    {/* <div class="input-group input-group-sm mt-2">
                                        <input type="text" class="form-control" placeholder="0.00" onChange={(e) => handleChange(e)} value={fromvalue} />

                                        <button class="ido-active-buy-max" type="button" id="button-addon2" onClick={max}>MAX</button>
                                        <span class="input-group-text" id="inputGroup-sizing-sm">
                                            <img src={image} alt="logo" />
                                        {SelectedTokenNetwork}
                                        </span>

                                    </div>
                                    <p>You will get {fromvalue * TokenPrice} {Tokensymbol} Token</p> 
                                    <div class="d-grid mt-3">
                                        <button class="ido-active-buy-approve" type="button" onClick={idosubmit}>BUY</button>
                                    </div> */}
                                    
                                </div>
                                {/* <div className="text-center mt-3 approve-connect-wallet-section">
                                        <button type="button" class="get-start-1">CONNECT WALLET</button>
                                    </div> */}
                            </div>
                            <p className="ido-active-text-5">We are preparing the sale</p>
                            <div class="progress mb-4">
                                <div class="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: "15%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p className="ido-active-text-7 mb-0">{TokenPrice} {SelectedTokenNetwork} = {TokenPrice} {Tokensymbol}</p>

                            {/* <p className="ido-active-text-8 mb-0">1 USD = 133.33 TNK</p> */}

                            <div className="d-flex flex-row gap-3 align-items-center mt-4 id-active-background-bg">
                                <div className="">Your Level:</div>
                                <div className="ms-auto" style={{ color: "#f4b844" }}>None</div>
                            </div>
                            <div class="progress mt-4 mb-2">
                                <div class="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: "0%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div className="d-flex flex-row gap-3 mb-2 align-items-center">
                                <div className=""><span className="ido-active-text-8">0.00%</span></div>
                                <div className="ms-auto"><span className="ido-active-text-8">0.00 / 30,000 {Tokensymbol}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            {/* <div class="modal fade ido-active-buy-section-modal" id="buyModal" tabindex="-1" aria-labelledby="buyModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="buyModalLabel">BUY NOW</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body py-5">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control" placeholder="0.00" />
                                <span class="input-group-text" id="inputGroup-sizing-sm"><img src={image} alt="logo" />{blockchainvalues}</span>
                            </div>
                            <div className="text-center mt-4">
                                <button type="button" class="get-start-1">BUY {name}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Completedidodetails;