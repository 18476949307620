import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";

import { BsDiscord } from 'react-icons/bs';
import { AiFillTwitterCircle, AiFillGithub, AiOutlineInstagram, AiFillLinkedin, AiFillYoutube, AiFillFacebook } from 'react-icons/ai';
import { FaTelegram, FaRedditAlien } from 'react-icons/fa';
import { BiRightArrowAlt } from 'react-icons/bi';

// import required modules
import { Navigation } from "swiper";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../config/config";
import Parser from 'html-react-parser';
import Navbar from "../../Pages/separate/Navbar";
import Footer from "../../Pages/separate/Footer";

export default function Pie() {
    const params = useParams();
    const [link, setLink] = useState("");
    const [blogData, setBlogData] = useState([]);
    useEffect(() => {
        setLink(params.link);
    }, [link]);

    // Get a single blog read data using useEffect
    useEffect(() => {
        if (link) {
            axios({
                method: "GET",
                url: `${API_URL}blogs/data/${link}`,
            }).then((data) => {
                setBlogData(data.data);
            }).catch((err) => console.log(err))
        }
    }, [link]);

    return (
        <div>

            <Navbar />
            <section className=" internal-page-padding-top py-5">
                <div className="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-9">
                            <div className="blog-read-more-section">
                                <section className="">
                                    <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-smooth-scroll="true" class="scrollspy-example-2" tabindex="0">
                                        <div>
                                            {/* <h1 className="blogread-text-1">D-wallet: The Ideal Choice for Novice and Professional Blockchain Users</h1> */}
                                            <h1 className="blogread-text-1"> {blogData.blog_title} </h1>

                                        </div>
                                        <div className="mt-4">
                                            <span class="blogread-text-2"> {blogData.date} </span>
                                        </div>
                                        {/* <img className="my-5" src="http://localhost:3000/static/media/blog5.cb500db53ea2e111459b.jpg" alt="" /> */}
                                        <img className="my-5" src={blogData.blogimg} alt="" />

                                        <div>
                                            {/* <p class="blogread-text-3">
                                                D-wallet: The Ideal Choice for Novice and Professional Blockchain Users, D-wallet: The Ideal Choice for Novice and Professional Blockchain Users
                                            </p> */}
                                        </div>
                                        <div>
                                            { Parser(`${blogData.blog}`)}
                                        </div>
                                        {/* { Parser(blogData.blog) } */}
                                    </div>
                                </section>
                                <aside>
                                    <ol className="p-0 m-0 mb-5">
                                        <div className="d-flex flex-column gap-3 flex-wrap justify-content-lg-end">
                                            <a href="https://twitter.com/d_ecosystem" target="_blank" rel="noopener noreferrer" alt="decosystem"><AiFillTwitterCircle className="blogread-icon-1" /></a>
                                            <a href="https://www.linkedin.com/company/d-ecosystem-b722a3237/" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillLinkedin className="blogread-icon-1" /></a>
                                            <a href="https://www.facebook.com/Desystem.io" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillFacebook className="blogread-icon-1" /></a>
                                            <a href="https://github.com/decosystem" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillGithub className="blogread-icon-1" /></a>
                                            <a href="https://t.me/d_ecosystem_group" target="_blank" alt="decosystem" rel="noopener noreferrer"><FaTelegram className="blogread-icon-1" /></a>
                                            <a href="https://www.youtube.com/@d-ecosystemchannel5605" alt="decosystem" target="_blank" rel="noopener noreferrer"><AiFillYoutube className="blogread-icon-1" /></a>
                                            <a href="https://discord.com/invite/TacRQNaVdH" alt="decosystem" target="_blank" rel="noopener noreferrer"><BsDiscord className="blogread-icon-1" /></a>
                                        </div>
                                    </ol>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}
