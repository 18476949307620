import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './Style.css';
import Home from "./Pages/Home"
import Idoform from "./Pages/idoform"
import Idolaunch from "./Pages/idolaunch"
import Idoformtoken from "./Pages/idoformtoken"
import Activeido from "./Pages/Activeido"
import Timer from "./Pages/timer"
import Leaderboard from "./Pages/Leaderboard"
import BlogRead from "./Pages/separate/Blogread"
import ActiveYourPlan from './Pages/ActiveYourPlan';
import NotFound from "./Pages/separate/404";
import Upcommingidodetails from "./Pages/upcomingIdodetails";
import Completedidodetails from "./Pages/Completedidodetails";

function App() {
  return (
    <div className="App">
            <Router>
                <div className="App">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/idoform" element={<Idoform />} />
                            {/* <Route path="/launchin" element={<Timer />} /> */}
                            <Route path="/leaderboard" element={<Leaderboard />} />
                            <Route exact path="/blog/:link" element={<BlogRead/>}/>
                            <Route exact path="/phase1" element={<Timer/>}/>
                            <Route path="*" element={<NotFound />} />
                            <Route path="/idolaunch" element={<Idolaunch />} />
                            <Route path="/idoformtoken" element={<Idoformtoken />} />
                            <Route path="/activeido/:id" element={<Activeido />} />
                            <Route path="/upcommingidodetails/:id" element={<Upcommingidodetails />} />
                            <Route path="/completedidodetails/:id" element={<Completedidodetails />} />
                            <Route path="/ActiveYourPlan/:DBID" element={<ActiveYourPlan />} />
                            
                        </Routes>
                </div>
            </Router>
    </div>
  );
}

export default App;
